.input_three_flex {
    display: flex;
    justify-content: space-between;
}

.input_three_flex .input_container {
    flex: 0 0 calc((100% - 130px) / 3);
    margin-bottom: 0;
}

.task_card_header + .input_three_flex {
    margin-top: 20px;
}


.api_prices_container p.card_labels > span {
    width: 150px;
}

.api_prices_container .card_action {
    padding-top: 0;
}

.api_prices_container .card {
    border-radius: 0;
}

.api_prices_container .input_three_flex_column .input_container {
    flex-direction: row;
    align-items: center;
}

.api_prices_container .input_three_flex_column .input_container label {
    margin-bottom: 0;
    width: 150px;
}

.api_prices_container.card_list.api_card_list.api_prices_container input[type="text"] {
    border-radius: 0;
    height: 32px;
    text-align: right;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    box-shadow: 0px 1px 0px rgb(0 0 0 / 4%);
}

.api_prices_container .card_flex {
    margin-bottom: 20px;
}
.api_prices_container.card_list.api_card_list.api_prices_container input[type="text"]:focus {
    box-shadow: 0 3px 0 0px rgb(179 187 242);
}

.btn.api_prices_btn {
    position: absolute;
    top: -116px;
    right: 0;
    z-index: 2;
    border-radius: 0;
    width: 150px;
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border-color: rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    background-color: inherit;
}

.api_prices_container {
    position: relative;
}

.btn.api_prices_btn span {
    color: rgb(255 255 255 / 90%);
}

.btn.api_prices_btn:hover {
    background-image: linear-gradient(135deg,rgba(255,255,255,.25),rgba(255,255,255,0.16));
}

.btn.api_prices_btn.api_prices_btn_loading span {color: rgb(255 255 255 / 60%);}

.card_unlimited_perc > label {
    color: #1a1f30;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 16px;
    display: block;
    margin-top: 10px;
}

.card_unlimited_perc .card {
    flex-direction: row;
}

.card_unlimited_perc span {
    height: 32px;
    border-bottom: 1px solid #dadce0;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s all ease;
}

.card_unlimited_perc input:focus + span {
    border-bottom-color: #8a83e3;
}