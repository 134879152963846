.tabs.tabs_page_header {
    margin: auto 0 0 0;
    justify-content: flex-start;
    padding: 0;
    width: 100%;
}

.tabs.tabs_page_header span.tab {
    padding: 8px 0 16px 0;
    flex-basis: inherit;
    margin-right: 50px;
}

.tabs_page_header .tab_controll {
  left: 15px;
  bottom: -1px;
}

.tabs_page_header .tab_controll.active {
    left: 146px;
}

.tabs_page_header .tab_controll.active2 {
    left: 241px;
}

.flex_layout {height: 100%;display: flex;position: absolute;top: 0;left: 0;width: 100%;}

.white_side {
    background: #fff;
    flex: 0 0 50%;
    box-shadow: -3px 0px 10px rgba(32, 32, 32, 0.07);
    border-right: 1px solid #dadce0;
}

.wrapper_flex {
    /* height: 100%; */
    display: flex;
    position: relative;
    flex-basis: 100%;
}

.wrapper_flex .flex_content {
    max-width: 1216px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    position: relative;
    padding: 35px;
}

.flex_right_side {
    flex: 0 0 50%;
    padding-right: 35px;
}

.flex_left_side {
    flex: 0 0 50%;
    padding-left: 35px;
}

.api_name span:first-child {
    padding: 1px 8px;
    border-radius: 4px;
    background: #d7f7c2;
    color: #006908;
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    margin-top: 2px;
}


.api_name {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
}

.api_name span:last-child {
    font-weight: 500;
    font-size: 18px;
    color: #1a1f30;
}

.api_endpoint_id > span:first-child {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #1a1f30;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
}

.api_endpoint_id {
    display: flex;
    align-items: center;
}

.api_endpoint_id > span:last-child {
    font-weight: 400;
    font-size: 13px;
    border-radius: 6px;
    padding: 4px 8px;
    display: flex;
    cursor: pointer;
    color: #414552;
    margin-left: auto;
    height: initial;
}

p.api_description {
    margin-bottom: 16px;
    font-size: 13px;
}

.flex_right_side h5 {text-transform: uppercase;letter-spacing: 1px;color: #1a1f30;font-size: 12px;line-height: 1;font-weight: 500;margin: 16px 0;}

.api_param {
    box-shadow: 0px 1px 1px rgb(0 0 0 / 4%);
    font-weight: 400;
    font-size: 13px;
    border-radius: 6px;
    padding: 8px;
    display: flex;
    background: #fff;
    color: #414552;
    margin-left: auto;
    border: 1px solid #dadce0;
    margin-bottom: 8px;
}

.api_param_name {
    flex: 0 0 210px;
    display: flex;
    align-self: flex-start;
}

.api_param_name span {
    padding: 3px 6px;
    border: 1px solid #dadce0;
    display: block;
    border-radius: 5px;
    color: #6c7784;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.2px;
    font-family: 'JetBrains Mono', monospace;
}

.api_param_desc span {
    color: #1a1f30;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    display: flex;
    width: 100%;
    height: 24px;
    align-items: center;
}

.api_param_desc {
    width: 100%;
}

.api_param_desc span span {
    margin-left: 8px;
    font-size: 11px;
    background: rgb(238 240 244);
    width: auto;
    padding: 0 7px;
    height: 20px;
    border-radius: 4px;
    color: #8b959e;
    font-weight: 400;
}

.api_param_desc p {
    margin-top: 2px;
}

.api_param_desc span span.active {
    background: rgb(33 57 218 / 20%);
    color: #2139da;
}

.flex_left_side .tabs {
    display: flex;
    border-bottom: 1px solid #dadce0;
    margin-bottom: 16px;
}

.flex_left_side .tabs .tab {
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    padding: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #8697a3;
    border-bottom: 2px solid transparent;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.flex_left_side .tabs .tab.active {
    border-bottom: 2px solid #2139da;
    color: #2139da;
    font-weight: 500;
}

.api_code {
    border-radius: 10px;
    position: relative;
    padding: 2px;
    background: rgb(77 78 74 / 60%);
}

.api_code_backgorund {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.api_code_background {
    background: radial-gradient(circle at -10% 20%, rgb(90, 92, 106) 0%, rgb(32, 45, 58) 81.3%);
    border-radius: 8px;
    opacity: .8;
    height: 100%;
    font-family: 'JetBrains Mono', monospace;
    color: #ffffff;
    font-size: 13px;
    letter-spacing: 1px;
    background: #3a3b36;
}

code {
    font-size: 11px !important;
    line-height: 1.5 !important;
}
.api_code_background > span code {width: 0;}
code span {
    font-family: 'JetBrains Mono' !important;
    letter-spacing: 0.5px;
}
.api_code_background > span {
    border-radius: 8px !important;
}

.flex_content_container {
    max-width: 1216px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
}

.api_param:last-child {
    margin-bottom: 0;
}

.api_box_border {
    content: "";
    display: block;
    height: 1px;
    background: #dadce0;
    width: 100%;
    position: relative;
    top: 35px;
}

.flex_content:last-child .api_box_border {
    display: none;
}




.logs_list {
    display: flex;
    flex-direction: column;
    position: relative;
    width: calc(100% + 34px);
}

.api_log {
    display: flex;
    height: 45px;
    align-items: center;
    padding: 0 17.5px 0 12.5px;
    cursor: pointer;
    border-top: 1px solid #dadce0;
    text-decoration: none;
}

span.api_log_status {
    font-size: 11px;
    width: auto;
    padding-top: 1px;
    height: 20px;
    border-radius: 4px;
    font-weight: 500;
    display: flex;
    background: #d7f7c2;
    color: #006908;
    border: 1px solid rgb(0 105 8 / 35%);
    margin-right: 10px;
    text-wrap: nowrap;
    flex: 0 0 55px;
    justify-content: center;
}

.api_log:hover {
    background: rgb(238 240 244 / 30%);
}

.api_log_endpoint {
    color: #414552;
    font-weight: 500;
    font-size: 13px;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    line-height: 1.2;
    padding-right: 25px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 223px);
}

span.api_log_length {
    color: #6c7784;
    font-weight: 400;
    font-size: 12px;
    text-wrap: nowrap;
    flex: 0 0 62px;
    text-align: right;
}

span.api_log_time {
    color: #6c7784;
    font-weight: 400;
    font-size: 12px;
    margin-right: 25px;
    text-wrap: nowrap;
    flex: 0 0 51px;
    text-align: right;
}
.api_log_date {
    text-transform: uppercase;
    color: #1a1f30;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    height: 40px;
    border-top: 1px solid #dadce0;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    cursor: pointer;
}

span.api_log_status.api_log_status_err {
    background: rgb(244 67 54 / 10%);
    color: #f44336;
    border: 1px solid hsl(4deg 90% 58% / 35%);
    padding: 1px 5px 0 5px;
}

.api_log_date:first-child {
    margin-top: -14px;
    border-top: 0;
}

.api_log.active {
    background: #f9fbfc;
    border-left: 3px solid #2139da;
    background-image: linear-gradient(90deg, rgb(33 57 218 / 5%), #f9fbfc 60%);
    border-bottom-color: red;
    border-top: 1px solid rgb(33 57 218 / 15%);
}

.api_log.active + div {
    border-top: 1px solid rgb(33 57 218 / 15%);
}

.api_log_detail {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.api_log_detail > span:first-child {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #1a1f30;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    flex: 0 0 199px;
}

.api_log_param > .api_log_detail > span:first-child {
    padding: 3px 6px;
    border: 1px solid #dadce0;
    display: block;
    border-radius: 5px;
    color: #6c7784;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.2px;
    font-family: 'JetBrains Mono', monospace;
    background: #fff;
    text-transform: inherit;
    flex-basis: inherit;
}

span.api_log_detail_value {
    margin: 0;
    font-size: 13px;
    color: #6c7784;
}

h1.api_log_title {
    margin: 0;
    line-height: 1;
    font-weight: 500;
    font-size: 21px;
    color: #374049;
    margin-bottom: 25px;
}

h6.api_log_subtite {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #1a1f30;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 10px;
}

.api_log_detail.api_log_detail_vert {
    flex-direction: column;
    align-items: flex-start;
}

.api_log_detail.api_log_detail_vert > span:first-child {
    flex-basis: initial;
    margin: 0;
    line-height: 1;
    font-weight: 500;
    font-size: 21px;
    color: #374049;
    margin-bottom: 25px;
    margin-top: 25px;
    text-transform: capitalize;
    letter-spacing: initial;
}

.api_log_detail.api_log_detail_vert .api_param {
    flex-wrap: wrap;
}

.api_log_detail.api_log_detail_vert .api_param .api_param_desc {
    flex: 0 0 calc(100% - 210px);
    margin-bottom: 10px;
}

.api_log_detail.api_log_detail_vert .api_param .api_param_desc:last-child {
    margin-bottom: 0;
}

.api_log_detail.api_log_detail_vert .api_code {
    width: 100%;
}

.api_log_param {
    width: 100%;
}

.api_log_detail:last-child {
    margin-bottom: 0;
}

.api_log_param span.api_log_detail_value {
    flex: 0 0 calc(100% - 199px);
    margin-left: auto;
}

.api_log:last-child {
    border-bottom: 1px solid #dadce0;
}





















.anayltics_flex {
    display: flex;
    flex: 0 0 calc(40% - 12.5px);
    flex-direction: column;
}

.analytics_wrapper .card {
    padding: 25px;
}

.anayltics_flex .card {
    width: 100%;
}


.wrapper.analytics_wrapper {
    display: flex;
}

.analytics_wrapper > .card {
    flex: 0 0 calc(60% - 12.5px);
    margin-right: 25px;
}

.analytics_wrapper h2 {
    font-weight: 500;
    font-size: 18px;
    color: #1a1f30;
    margin: 0 0 15px 0;
    line-height: 1;
}

#call_consumption_graph {
    height: 410px;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
}

div#lantecy_graph {
    
    height: 150px;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
}

#call_count_graph {
    height: 150px;
}

.card_title {
    display: flex;
    margin-bottom: 12px;
    justify-content: space-between;
}

.graph_guide {
    display: flex;
}

.graph_quide_item span:first-child {
    display: block;
    height: 10px;
    width: 10px;
    background: #2139da;
    border-radius: 3px;
    margin-right: 10px;
}

.graph_quide_item {
    display: flex;
    align-items: center;
    margin-right: 20px;
    padding: 6px 10px 6px 8px;
    border-radius: 4px;
    cursor: pointer;
}

.graph_quide_item:last-child {
    margin-right: 0;
}

.graph_quide_item span:last-child {
    color: #6c7784;
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
}

.card_title h2 {
    margin-bottom: 0;
}

.card_title .graph_guide {
    margin-top: -3px;
    margin-right: -3px;
}

.graph_quide_item:hover {
    background: rgb(238 240 244 / 60%);
}

.y_axis {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #6c7784;
    font-weight: 300;
    font-size: 12px;
    padding: 0 10px 0 5px;
}

.graph_body {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.background_lines {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.background_lines span {
    height: 1px;
    background: #eceff1;
    margin-top: 20px;
    position: relative;
    top: -10px;
}

.y_axis span {
    display: flex;
    align-items: center;
    height: 19px;
}

.x_axis {
    display: flex;
    align-items: center;
    margin-bottom: -20px;
}

.x_axis span {
    text-align: center;
    font-size: 11px;
    color: #6c7784;
    width: 100%;
}

.bars {
    height: 100%;
    padding-bottom: 14px;
    display: flex;
    justify-content: space-around;
    position: relative;
}

.bar {
    height: calc(100% + 20px);
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc((100% / 7) - 20px);
    max-width: 55px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
    align-items: center;
    padding-top: 27px;
    margin-top: -20px;
}

span.bar_succ_call {
    height: 100%;
    width: 18px;
    background: rgb(33 57 218 / 30%);
    border-radius: 6px 6px 0 0;
    border: 2px solid rgb(33 57 218 / 70%);
    border-bottom: 0;
    flex: 0 0 262px;
}

span.bar_err_call {
    flex: 0 0 87px;
    width: 18px;
    position: relative;
}

.bar:hover {
    background: linear-gradient(to top, rgb(33 57 218 / 15%) 50%, transparent);
}

span.bar_err_call span:first-child {
    height: 100%;
    background: linear-gradient(to bottom, #fbc6c2 5px, rgb(244 67 54 / 30%) 5px);
    display: block;
    border: 2px solid rgb(244 67 54 / 70%);
    width: 100%;
    border-radius: 6px 6px 0 0;
    border-bottom: 0;
    position: relative;
    z-index: 1;
}

span.bar_err_call span:last-child {
    background: #bcc3f4;
    border-left: 2px solid #4f62e2;
    border-right: 2px solid #4f62e2;
    height: 5px;
    width: 100%;
    display: none;
    position: absolute;
    top: 0;
}

span.bar_succ_call + span.bar_err_call span:last-child {
    display: block;
}

.bar:hover span.bar_succ_call {
    background: #4f62e2;
    border: 0;
}


.bar:hover span.bar_err_call span:first-child {
    background: #f66a60;
    border: 0;
}

.bar:hover span.bar_err_call span:last-child {
    background: #4f62e2;
    border: 0;
}

span.point {
    height: 6px;
    width: 6px;
    background: #2139da;
    border-radius: 9px;
    position: relative;
    margin-bottom: 0;
    bottom: -3px;
    position: relative;
    z-index: 2;
}

    #lantecy_graph .bar {height: calc(100% + 20px);padding-top: 21px;position: relative;flex: 0 0 100%;}



.bar_line {
    height: 59px;
    clip-path: polygon(95% 0, 100% 0, 5% 100%, 0% 100%);
    background: #2139da;
    position: absolute;
    width: 100%;
    left: calc(100% / 14);
    left: 50%;
}

.triangle_left {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 68px 55px 0 0;
    border-color: #ffffff transparent transparent transparent;
    position: absolute;
    left: 24px;
    top: 0;
    z-index: 1;
}

.triangle_left_border {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 68px 55px 0 0;
    border-color: #4b00ff transparent transparent transparent;
    position: relative;
    left: 27px;
    top: 0;
}

.triangle_right {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 55px 55px 0;
    border-color: transparent #ffffff transparent transparent;
    position: absolute;
    left: 27px;
    top: 24px;
    z-index: 1;
}

.triangle_right_border {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 56px 40px 0;
    border-color: transparent #4b00ff transparent transparent;
    position: relative;
    left: 24px;
    top: 27px;
}


.bars_line {
    height: calc(100% - 20px);
    display: flex;
    position: absolute;
    width: calc(100% - 0px);
    top: 10px;
    justify-content: space-around;
    align-items: flex-end;
}

.title_search_info {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.api_title_skeleton {
    height: 30px;
    border-radius: 6px;
    width: 280px;
    display: block;
    position: relative;
    overflow: hidden;
    background-color: rgb(240 241 243 / 30%);
}

.api_p_skeleton {
    height: 18px;
    margin-top: 8px;
    border-radius: 6px;
    width: 580px;
    display: block;
    position: relative;
    overflow: hidden;
    background-color: rgb(240 241 243 / 30%);
}

.api_p_skeleton + .api_p_skeleton {
    width: 170px;
}

.api_tab_skeleton {
    display: block;
    position: relative;
    overflow: hidden;
    background-color: rgb(240 241 243 / 30%);
    height: 12px;
    border-radius: 3px;
    width: 100px;
    margin-right: 40px;
    margin-bottom: 20px;
}

.tabs_header_side .btn.btn_glass.btn_w_icon svg {
    position: absolute;
    z-index: 1;
    height: 20px;
    top: 8px;
    left: 11px;
    width: 20px;
    fill: rgb(255 255 255 / 60%);
    transform: none;
    margin-right: 0;
}

.btn.btn_glass.btn_w_icon {
    padding-left: 44px;
    padding-right: 18px;
}

.btn_wrapper {
    display: flex;
    flex-direction: column;
    color: rgb(255 255 255 / 60%);
}

.btn_wrapper span:first-child {
    font-weight: 300;
    font-size: 8.5px;
    text-transform: uppercase;
    letter-spacing: 0.8px;
}

.btn_wrapper span:last-child {
    font-size: 12px;
    margin-top: 1px;
    font-weight: 500;
}

.drawer_container {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
    display: flex;
    top: -999%;
    transition: 0s ease top 0.2s;
}

.drawer_container.active {
    top: 0;
    transition: 0s ease top;
}

.drawer_background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgb(0 0 0 / 55%);
    opacity: 0;
    transition: 0.2s ease opacity;
}

.drawer_wrapper {
    height: 100%;
    width: 400px;
    background: white;
    margin-left: auto;
    position: relative;
    border-left: 1px solid #dadce0;
    box-shadow: 0 0 12px 2px rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: column;
    right: -450px;
    transition: 0.2s right;
}

.drawer_head {
    display: flex;
    padding: 12px 15px 12px 20px;
    border-bottom: 1px solid #dadce0;
    align-items: center;
}

.drawer_select_list {
    height: 100%;
    overflow: auto;
    padding: 20px;
}

.drawer_head h2 {
    margin: 0;
    line-height: 1;
    font-weight: 500;
    font-size: 16px;
    color: #1a1f30;
}

span.drawer_close {
    display: flex;
    margin-left: auto;
    cursor: pointer;
    border-radius: 30px;
    padding: 5px;
}

span.drawer_close svg {
    fill: #8697a3;
}

span.drawer_close:hover {
    background: rgb(238 240 244);
}

.drawer_select_item {
    margin-bottom: 5px;
    color: #414758;
    font-weight: 400;
    line-height: 1.2;
    font-size: 13px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 10px;
    color: #8b959e;
}

.drawer_select_item:hover {
    background: rgb(238 240 244);
    border-radius: 10px;
}

.drawer_select_item:last-child {
    margin-bottom: 0;
}

.drawer_select_item span:first-child {
    flex: 0 0 130px;
}

.drawer_select_item svg {
    margin-left: auto;
    opacity: 0;
    height: 20px;
    width: 20px;
}

.drawer_select_item:hover svg {
    opacity: 1;
    fill: #c3c9cf;
}

.drawer_select_item.active svg, .drawer_select_item.active:hover svg {
    opacity: 1;
    fill: #51c693;
}

.drawer_container.active .drawer_background {opacity: 1;}

.drawer_container.active .drawer_wrapper {
    right: 0;
}
.api_plans {
    display: flex;
}

.api_plans_list {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.api_plans_features {
    flex: 0 0 300px;
    padding-top: 158px;
}

.api_plans_item {
    flex: 0 0 calc((100% - 80px) / 4);
    padding: 20px 0 22px 0;
    border-radius: 10px;
    border: 1px solid #dadce0;
}

.api_plans_feature span {
    font-size: 13px;
    color: #8b959e;
}

.api_plans_feature {
    border-bottom: 1px solid #dadce0;
    padding: 10px 0;
}

.api_plans_features h3 {
    font-size: 13px;
    color: #374049;
    font-weight: 500;
    border-bottom: 1px solid #dadce0;
    padding: 10px 0;
    margin: 0;
}

.api_plans_list_feature {
    text-align: center;
    border-top: 1px solid #dadce0;
    margin-top: 41px;
}

span.api_plans_name {
    font-size: 16px;
    font-weight: 500;
    color: #1a1f30;
    line-height: 1;
}

.api_plans_price {
    display: flex;
    align-items: flex-end;
    line-height: 1;
}

span.api_plans_price_value {
    font-size: 34px;
    font-weight: 500;
    color: #1a1f30;
}

span.api_plans_price_mexico {
    font-size: 13px;
    font-weight: 500;
    color: #1a1f30;
    margin-bottom: 3px;
    margin-left: 2px;
}

.api_plans_highlight {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}

span.api_plans_accuracy {
    font-size: 12px;
    color: #8697a3;
}

span.api_plans_accuracy + .btn {
    margin: 12px 0 0 0;
}

.api_plans_list_feature .api_plans_feature span {
    color: #374049;
    font-weight: 500;
}

span.api_plans_tag {
    color: #0eae69;
    background: rgb(225 245 237);
    padding: 4px 6px 3px 6px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 5px;
    margin-left: 5px;
    line-height: 1;
}

.api_plans_header {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    height: 20px;
}

.btn.btn_semi_primary span {
    color: #2139da;
}

.api_plans_item:hover {
    background: #f9fbfc;
}

.api_name.api_name_skeleton span:first-child {
    height: 23px;
    width: 51.67px;
    display: block;
    position: relative;
    overflow: hidden;
    background-color: #f0f1f3;
}

.api_name.api_name_skeleton span:last-child {
    height: 18px;
    width: 220px;
    border-radius: 4px;
    margin-top: 3.5px;
    display: block;
    position: relative;
    overflow: hidden;
    background-color: #f0f1f3;
}

.api_description_skeleton {
    margin-bottom: 16px;
}

.api_description_skeleton span:first-child {
    height: 16px;
    width: 100%;
    display: block;
    border-radius: 4px;
    background-color: #f0f1f3;
    display: block;
    position: relative;
    overflow: hidden;
}

.api_description_skeleton span:last-child {
    height: 16px;
    background: #f0f1f3;
    width: 30%;
    display: block;
    border-radius: 4px;
    margin-top: 8px;
    position: relative;
    overflow: hidden;
}

.api_endpoint_id.api_endpoint_id_skeleton > span:first-child {
    height: 12px;
    background: #f0f1f3;
    width: 80px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
}

.api_endpoint_id.api_endpoint_id_skeleton > span:last-child {
    height: 30px;
    background: #f0f1f3;
    width: 300px;
    box-shadow: none;
    border: 0;
    position: relative;
    overflow: hidden;
}

span.api_documentation_h5_skeleton {
    height: 12px;
    background: #f0f1f3;
    width: 80px;
    border-radius: 4px;
    display: block;
    margin: 16px 0;
    position: relative;
    overflow: hidden;
}

.api_documentation_param_skeleton {
    height: 60px;
    background: #f0f1f3;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 8px;
    display: block;
    position: relative;
    overflow: hidden;
}

.api_documentation_param_skeleton:last-child {
    margin-bottom: 0;
}

.flex_left_side .tabs .tab.tab_skeleton {
    height: 12px;
    width: 100px;
    background: #f0f1f3;
    padding: 0;
    margin: 12px;
    border-radius: 4px;
    display: block;
    position: relative;
    overflow: hidden;
}

.tabs.tabs_skeleton {
    border-bottom: 1px solid #f0f1f3;
}

.api_code.api_code_skeleton {
    background: #f0f1f3;
    height: 215px;
    display: block;
    position: relative;
    overflow: hidden;
}

span.api_plans_price_value.api_plans_price_value_skeleton {
    height: 34px;
    width: 77px;
    border-radius: 4px;
    margin-right: 5px;
    display: block;
    position: relative;
    overflow: hidden;
    background: #f0f1f3;
}

.api_plans_btn_skeleton {
    height: 36px;
    margin: 12px 0;
    border-radius: 8px;
    background: #f0f1f3;
    display: block;
    position: relative;
    overflow: hidden;
}

.api_plans_list_feature_skeleton {
    margin-top: 41px;
    height: 139px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.api_plans_list_feature_skeleton .api_plans_feature {
    height: 100%;
    width: 400%;
    pointer-events: none;
}

.api_plans_list_feature_skeleton .api_plans_feature:first-child {
    border-top: 1px solid #dadce0;
}

.api_log_date.api_log_date_skeleton span {
    height: 12px;
    width: 90px;
    display: block;
    position: relative;
    overflow: hidden;
    background: #f0f1f3;
    border-radius: 4px;
}

.api_log.api_log_skeleton span.api_log_status {
    display: block;
    position: relative;
    overflow: hidden;
    background: #f0f1f3;
    border: 0;
}

.api_log.api_log_skeleton .api_log_endpoint {
    height: 15px;
    display: block;
    position: relative;
    overflow: hidden;
    background: #f0f1f3;
    width: 150px;
    border-radius: 4px;
}

.api_log.api_log_skeleton span.api_log_time,
.api_log.api_log_skeleton span.api_log_length {
    height: 12px;
    display: block;
    position: relative;
    overflow: hidden;
    background: #f0f1f3;
    border-radius: 4px;
}

.api_log_detail.api_log_detail_skeleton span.api_log_status {
    background: #f0f1f3;
    border-color: #f0f1f3;
    display: block;
    position: relative;
    overflow: hidden;
}

.api_log_detail.api_log_detail_skeleton span.api_log_detail_value {
    height: 13px;
    background: #f0f1f3;
    width: 180px;
    border-radius: 4px;
    display: block;
    position: relative;
    overflow: hidden;
}

.api_log_detail.api_log_detail_skeleton {
    height: 20px;
}

.api_log_detail.api_log_detail_vert.api_log_detail_skeleton .api_log_param > .api_log_detail > span.api_log_deatil_name {
    height: 20px;
    width: 80px;
    border-color: #f0f1f3;
    background: #f0f1f3;
    display: block;
    position: relative;
    overflow: hidden;
}

.api_log_detail.api_log_detail_vert.api_log_detail_skeleton {
    height: inherit;
}

.api_plans_header + p span {
    color: #6c7784;
    font-weight: 500;
}

.api_plans_header + p {
    margin-bottom: 16px;
}

.tabs_header_label {
    color: rgb(255 255 255 / 90%);
    letter-spacing: 0.3px;
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    align-self: center;
    margin-right: 16px;
}

.btn.btn_loading.btn_semi_primary span {
    color: rgb(33 57 218 / 65%);
}

.btn.btn_loading.btn_semi_primary {border-color: #eceff1;cursor: wait;}

.api_plans_item.api_plans_item_activated .btn.btn_semi_primary {
    background: rgb(250 250 250);
    pointer-events: none;
}
.api_plans_item.api_plans_item_activated .btn.btn_semi_primary span {
    color: #8b959e;
}

.api_plans_item.api_plans_item_activated {
    background: #fbfbfc;
}

.api_plans_item.api_plans_item_activated .btn.btn_primary {
    border-color: transparent;
    pointer-events: none;
    box-shadow: none;
    background: rgb(10 174 103 / 12%);
}

.api_plans_item.api_plans_item_activated .btn.btn_primary span {
    color: #0aae67;
}

.api_plans_item.api_plans_item_activated span.api_plans_accuracy {
    color: #6c7784;
    font-weight: 500;
}

.analytics_count_label {
    margin: 0;
    color: #8b959e;
    font-size: 12px;
    line-height: 1.4;
    font-weight: 400;
}

.analytics_count_number {
    line-height: 1;
    font-size: 34px;
    font-weight: 500;
    color: #1a1f30;
}

.card.analytics_count_card {
    margin-right: auto;
    width: 100%;
    margin-bottom: 25px;
    height: 100%;
}

.card.analytics_error_rate_card h3 {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    color: #1a1f30;
    line-height: 1;
}

.analytics_error_rate {
    height: 10px;
    border: 1px solid #dadce0;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    padding: 1px;
}

.analytics_error_rate_bar {
    height: 6px;
    width: 50%;
    background: #f34343;
    border-radius: 6px;
}

.analytics_error_rate_title {
    display: flex;
    align-items: center;
}

.analytics_error_rate_title span {
    font-size: 14px;
    font-weight: 500;
    color: #1a1f30;
    line-height: 1;
    margin-left: auto;
}

.card.analytics_error_rate_card {
    width: 100%;
    margin-bottom: 25px;
}

.anayltics_flex > .card:last-child {
    flex: 0 0 250px;
}

.anayltics_flex .card_flex {
    margin-bottom: 25px;
    justify-content: space-between;
}

.anayltics_flex .card_flex .card {
    height: 100%;
    width: calc((100% - 25px) / 2);
}

span.analytics_call_count {
    font-size: 34px;
    font-weight: 500;
    color: #1a1f30;
    line-height: 1;
}

.card h3 {
    font-weight: 500;
    font-size: 13px;
    color: #6c7784;
    margin: 0 0 15px 0;
    line-height: 1;
}

.apexcharts-text tspan {
    color: #6c7784;
    font-size: 11px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 300;
}
.apexcharts-gridline {
    stroke: #eceff1;
}

.apexcharts-xaxis-tick {
    display: none;
}

.apexcharts-grid-borders line:last-child {
    display: none;
}

.apexcharts-tooltip-text-y-label {
    color: #6c7784;
    font-size: 12px;
    font-family: 'Poppins', sans-serif !important;
}

.apexcharts-tooltip-series-group.apexcharts-active, .apexcharts-tooltip-series-group:last-child {
    padding-bottom: 0 !important;
}

.apexcharts-tooltip-marker {
    border-radius: 3px !important;
    background-color: rgb(188, 195, 244) !important;
    border: 2px solid rgb(79, 98, 226);
    height: 10px !important;
    width: 10px !important;
}

span.apexcharts-tooltip-text-y-value {
    color: #374049;
    font-size: 12px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
}

.apexcharts-tooltip.apexcharts-theme-light {
    border: 1px solid #dadce0;
}
.btn.btn_small.btn_success.btn_loading {
    border-color: #eceff1;
    cursor: wait;
}

.btn.btn_small.btn_success.btn_loading span {
    color: rgb(10 174 103 / 65%);
}
span.apexcharts-tooltip-marker[style="background-color: rgba(243, 67, 67, 0.85);"] {
    background-color: rgb(252, 198, 194) !important;
    border: 2px solid rgb(246, 106, 96);
}

.logs_list .history_empty_container {
    align-items: flex-start;
}

.card_skeleton {
    display: block;
    position: relative;
    overflow: hidden;
    background: #f0f1f3;
    border: 0;
}

.analytics_wrapper .card {
    transition: initial;
}

.react-date-picker__wrapper {
    height: 36px;
    background-image: linear-gradient(135deg,rgba(255,255,255,.05),rgba(255,255,255,0.02));
    border: 1px solid rgba(255,255,255,0.25) !important;
    border-radius: 6px;
    flex-direction: row-reverse;
    align-items: center;
    margin-left: 8px;
    background: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    cursor: pointer;
}

.react-date-picker__inputGroup input, span.react-date-picker__inputGroup__divider {
    color: rgb(255 255 255 / 90%);
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    text-align: center;
}

input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__year {
    width: 34px !important;
}

button.react-date-picker__clear-button.react-date-picker__button {
    display: none;
}

.react-date-picker__button svg {
    fill: rgb(255 255 255 / 90%);
    height: 18px;
    width: 18px;
    margin-right: 8px;
}

.react-date-picker__button {
    display: flex;
    align-items: center;
    padding: 0 16px !important;
    height: 100%;
}

.react-date-picker__inputGroup {
    height: 20px;
    line-height: 1;
    display: none;
}

.react-date-picker__button span {
    color: rgb(255 255 255 / 90%);
    font-size: 13px;
    line-height: 1;
    font-weight: 500;
}

.btn.btn_glass.btn_glass_calendar {
    padding: 0;
}

.react-date-picker__wrapper:hover {
    background-image: linear-gradient(135deg,rgba(255,255,255,.25),rgba(255,255,255,0.16));
}

.react-date-picker__calendar {
    margin-top: 20px;
    width: 298px !important;
}

.react-calendar {
    border: 1px solid #dadce0 !important;
    background: #fff !important;
    border-radius: 8px;
    box-shadow: 0 1px 3px 3px rgba(60,64,67,.05);
    padding: 10px 18px;
    width: 298px !important;
}

.react-calendar .react-calendar__tile {
    font-size: 13px;
    color: #1a1f30;
    font-weight: 500;
    height: 32px;
    flex: 0 0 32px !important;
    margin-right: 6px !important;
    margin-bottom: 6px;
    padding: 0;
    border-radius: 5px;
}

.react-calendar .react-calendar__month-view__days__day--neighboringMonth {
    color: #8697a3;
    font-weight: 400;
}

.react-calendar__month-view__days .react-calendar__tile:nth-child(7n) {
    margin-right: 0 !important;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background: rgb(238 240 244) !important;
}

.react-calendar__month-view__weekdays__weekday {
    font-size: 13px;
    color: #6c7784;
    padding: 0;
    font-weight: 400;
    height: 30px;
    flex: 0 0 32px !important;
    margin-right: 6px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    overflow: hidden;
    width: 21px;
    display: block;
    overflow-wrap: break-word;
    height: 17px;
    text-transform: capitalize;
}

.react-calendar__month-view__weekdays__weekday:nth-child(5) abbr {
    width: 13px;
}

.react-calendar__navigation .react-calendar__month-view__weekdays__weekday:nth-child(7) {
    margin-right: 0 !important;
}

button.react-calendar__navigation__arrow {
    border-radius: 8px;
    -webkit-backdrop-filter: blur(0.1px);
    backdrop-filter: blur(0.1px);
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    min-width: initial !important;
    font-size: 20px;
    font-family: monospace;
    line-height: 28px;
}

button.react-calendar__navigation__label {
    font-size: 13px;
    color: #1a1f30;
    font-weight: 500;
    height: 30px;
    padding: 0;
    border-radius: 5px;
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background: rgb(238 240 244) !important;
}

.react-calendar__navigation {
    align-items: center;
    margin-bottom: 16px !important;
    height: 30px !important;
}


.react-calendar__tile--now {
    background: transparent !important;
    border: 1px solid #dadce0 !important;
}

.react-calendar__tile--now abbr {
    color: #2139da;
}

.react-calendar__tile.react-calendar__tile--active, .react-calendar__tile.react-calendar__tile--active:hover,
.react-calendar__tile.react-calendar__tile--hasActive, .react-calendar__tile.react-calendar__tile--hasActive:hover {
    background: #2139da !important;
    background-image: linear-gradient(80deg, #2017b1, #3545d9) !important;
    opacity: 0.8;
    color: #fff;
    border: 0 !important;
}

.react-calendar__tile--active abbr, .react-calendar__tile--hasActive abbr {
    color: #fff;
}

.react-calendar__year-view .react-calendar__tile {
    flex-basis: calc((100% - 12px) / 3) !important;
    margin: 0 6px 6px 0 !important;
    padding: 0 !important;
    height: 32px;
    font-size: 12px;
}

.react-calendar__decade-view__years .react-calendar__tile {
    flex-basis: calc((100% - 24px) / 5) !important;
    margin: 0 6px 6px 0 !important;
    padding: 0 !important;
    height: 32px;
    font-size: 12px;
}

.react-calendar__century-view .react-calendar__tile {
    flex-basis: calc((100% - 6px) / 2) !important;
    margin: 0 6px 6px 0 !important;
    padding: 0 !important;
    height: 32px;
    font-size: 12px;
}

.react-calendar__year-view .react-calendar__tile:nth-child(3n),
.react-calendar__decade-view .react-calendar__tile:nth-child(5n),
.react-calendar__century-view .react-calendar__tile:nth-child(2n) {
    margin-right: 0 !important;
}


a.api_log.api_log_hide {
    display: none;
}

.api_log_date > svg {
    fill: #6c7784;
    height: 18px;
    width: 18px;
    position: absolute;
    left: 0;
}

.api_incoive_popup {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.api_incoive_popup_background {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgb(0 0 0 / 40%);
}

.api_incoive_popup_container {
    padding: 25px;
    position: relative;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 6px 22px 0 rgba(60,64,67,.5);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid #dadce0;
    transition: 0.2s all ease;
    width: 100%;
    max-width: 440px;
}

.api_invoice_popup_action {
    display: flex;
    align-self: flex-end;
}

.api_invoice_popup_action .btn {
    width: 110px;
}

.api_invoice_popup_action .btn + .btn {
    margin-left: 15px;
}

p.tax_text {
    margin-top: 0;
    margin-left: auto;
}

h2.api_plans_title {
    font-weight: 500;
    font-size: 18px;
    color: #1a1f30;
    margin: 0 0 25px 0;
    line-height: 1;
}

p.api_plans_description {
    font-size: 13px;
    color: #6c7784;
    padding: 12px 18px;
    border-radius: 9px;
    border: 1px solid #e4e5f8;
    margin-right: auto;
    width: calc(((100% - 80px) / 4)*2 + (80px / 3));
    background: #f9fbfc;
}

p.api_plans_description a {
    text-decoration: none;
    color: #2139da;
    font-weight: 500;
    text-transform: capitalize;
}

.api_plans_footer {
    display: flex;
    margin-top: 25px;
}

.api_plans_title_skeleton {
    height: 18px;
    width: 600px;
    border-radius: 4px;
    display: block;
    position: relative;
    overflow: hidden;
    background: #f0f1f3;
    margin: 0 0 25px 0;
}