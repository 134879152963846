.title_search_info.title_search_info_center {
    text-align: center;
    align-items: center;
}

.contact_form {
    max-width: 570px;
    margin: 0 auto;
    width: 100%;
    padding: 25px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px 1px rgba(60,64,67,.03);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid #dadce0;
    transition: 0.2s all ease;
    position: relative;
    z-index: 2;
    margin-top: -50px;
}