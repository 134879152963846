.admin_body .title_search {
    height: 218px;
    align-items: flex-start;
    padding-top: 40px;
}

.tabs_admin {
    display: flex;
    border-bottom: 1px solid rgb(255 255 255 / 29%);
    width: 100%;
}

.tabs_admin .tab {
    display: flex;
    flex-direction: column;
    width: 140px;
    padding: 11px;
    cursor: pointer;
}

.tabs_admin .tab svg {
    fill: rgb(255 255 255 / 60%);
    height: 28px;
    width: 28px;
}

.tabs_admin .tab span {
    color: rgb(255 255 255 / 60%);
    font-size: 14px;
    margin-top: 8px;
    font-weight: 500;
}

.tabs_admin .tab:hover {
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
}

.tabs_admin .tab.active {
    background-image: linear-gradient(135deg,rgba(255,255,255,.35),rgba(255,255,255,0.16));
    border-color: rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
}

.tabs_admin .tab.active svg {
    fill: #fff;
}

.tabs_admin .tab.active span {
    color: #fff;
}

.admin_body .btn.btn_glass.logout_btn {
    margin-left: auto;
    border-radius: 0;
}

.task_card {
    background: #fff;
    box-shadow: 0 1px 3px 1px rgba(60,64,67,.03);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid #dadce0;
    padding: 20px;
    margin-bottom: 50px;
}

.task_card_header {
    display: flex;
    align-items: center;
}

.task_card_header h1 {
    margin: 0;
    line-height: 1;
    font-weight: 500;
    font-size: 18px;
    color: #374049;
}

span.task_card_label {
    background: #e4e7fb;
    font-size: 12px;
    font-weight: 500;
    color: #2139da;
    padding: 4px 7px;
    display: flex;
    align-items: center;
    border: 1px solid #a4aef0;
    margin-left: 20px;
}

span.task_card_label svg {
    fill: #2139da;
    height: 16px;
    width: 16px;
    margin-right: 8px;
}

.task_progress {
    background: #fff;
    font-size: 12px;
    font-weight: 500;
    color: #2139da;
    display: flex;
    align-items: center;
    border: 1px solid #b8c2c9;
    margin-left: auto;
    height: 28px;
    width: 210px;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.task_bar {
    width: 100%;
    height: 3px;
    background: rgb(238 240 244);
    position: absolute;
    bottom: 0;
}

.task_progress span {
    font-weight: 600;
    color: #0d24bb;
    margin-right: 1px;
    font-size: 13px;
}

.task_progress p {
    line-height: 1;
    font-size: 11px;
    color: #374049;
    margin-bottom: 2px;
}

.task_bar_fill {
    height: 100%;
    width: 90px;
    background: #2139da;
}

.task_list_item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex: 0 0 calc((100% - 40px) / 3);
    margin-right: 20px;
}

.task_list_item svg {
    fill: #b8bec4;
    height: 21px;
    width: 21px;
}

.task_body {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #dadce0;
}

.task_list_check {
    display: flex;
    flex-wrap: wrap;
}

.task_list_item:nth-child(3n) {
    margin-right: 0;
}

.task_list_item span {
    margin-left: 8px;
    font-size: 13px;
    color: #374049;
}

.task_list_item.active svg {
    fill: #0aae67;
}

span.task_card_label.task_card_label_pending {
    background: navajowhite;
    border-color: #ffac32;
    color: #b56c00;
}

span.task_card_label.task_card_label_pending svg {
    fill: #b56c00;
}

.task_card_header h1 + span.task_card_label {
    margin-left: auto;
}

span.task_card_label.task_card_label_draft {
    background: #eceff1;
    border-color: #bcc2c7;
    color: #6c7784;
}

span.task_card_label.task_card_label_draft svg {
    fill: #6c7784;
    position: relative;
    top: -1px;
}

.admin_auth_container {
    display: flex;
    position: fixed;
    height: 100%;
    width: 100%;
    padding: 25px;
    justify-content: center;
    background: #00000069;
    align-items: center;
    z-index: 1;
}

.admin_auth_container .auth_side {
    position: relative;
    height: initial;
    width: 420px;
    overflow: hidden;
    padding: 40px 0;
    width: initial;
    left: 0;
    top: 0;
}

.admin_auth_container .auth_side_login {
    position: relative;
    left: initial;
}

input.admin_input {background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));border: 1px solid rgba(255,255,255,0.25);-webkit-backdrop-filter: blur(0.5px);backdrop-filter: blur(0.5px);background-color: transparent;color: #fff;height: 42px;width: 340px;padding-left: 45px;padding-right: 45px;}

input.admin_input:hover {
    background-image: linear-gradient(135deg,rgba(255,255,255,.25),rgba(255,255,255,0.1));
    border: 1px solid rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    background-color: transparent;
    color: #fff;
    box-shadow: none;
}

input.admin_input:focus {
    background-image: linear-gradient(135deg,rgba(255,255,255,.25),rgba(255,255,255,0.1));
    border: 1px solid rgba(255,255,255,0.45);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    background-color: transparent;
    color: #fff;
    box-shadow: none;
}

.input_admin_container {
    position: relative;
}

.input_admin_container svg {
    position: absolute;
    z-index: 1;
    fill: rgb(255 255 255 / 60%);
    height: 26px;
    width: 26px;
    left: 10px;
    top: 8px;
}

.admin_btn {
    position: absolute;
    right: 5px;
    top: 5px;
    height: 32px;
    width: 32px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border-color: rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    border: 1px solid rgba(255,255,255,0.25);
    cursor: pointer;
    box-shadow: 0 0 10px rgb(0 0 0 / 25%);
}

.input_admin_container .admin_btn svg {
    position: static;
}

.admin_btn:hover {
    background-image: linear-gradient(135deg,rgba(255,255,255,.3),rgba(255,255,255,0.12));
}

.admin_btn:active {
    background-image: linear-gradient(135deg,rgba(200,200,200,.2),rgba(220,220,220,0.06));
}

.admin_auth_container .auth_side_content {
    position: static;
}

.admin_auth_container .auth_side_backgorund {
    position: absolute;
    width: 100%;
}

.task_card_skeleton {
    height: 204px;
    display: block;
    position: relative;
    overflow: hidden;
    background-color: #f0f1f3;
    width: 100%;
    margin-bottom: 50px;
}

.tab_admin_skeleton {
    display: block;
    position: relative;
    overflow: hidden;
    background-color: rgb(240 241 243 / 30%);
    width: 140px;
    height: 79px;
    margin-right: 10px;
}

.admin_btn.admin_btn_error {
    background-image: linear-gradient(135deg,rgb(244 67 54 / 65%),rgb(244 67 54 / 46%));
    border: 1px solid rgb(154 29 20 / 55%);
    border: 1px solid rgb(242 187 193 / 40%);
}

.admin_btn.admin_btn_error svg {
    fill: #f2bbc1;
}

span.task_card_label.task_card_label_done {
    color: #0aae67;
    border-color: #77d2aa;
    background: #d8f2e7;
}

span.task_card_label.task_card_label_done svg {
    fill: #0aae67;
}

.btn.api_card_btn {
    border-radius: 0;
}