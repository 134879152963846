.admin_add_api_flex {
    display: flex;
}

.steps_indicator {
    padding-right: 50px;
    flex: 0 0 400px;
    overflow: hidden;
}

.card.add_api_card {
    width: 100%;
    padding: 25px;
    margin-bottom: 35px;
    border-radius: 0;
}

.step_indicator_info h4 {
    margin: 0 0 4px 0;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    color: #6c7784;
}

.active .step_indicator_info h4 {
    color: #1a1f30;
}

.step_indicator {
    display: flex;
    margin-top: 26px;
    position: relative;
}

.step_indicator.active .step_icon {
    background: #fff;
    box-shadow: 0 1px 3px 1px rgba(60,64,67,.03);
    color: #2139da;
    font-weight: 500;
}

.step_indicator:first-child {
    margin-top: 0;
}

.step_indicator_info {
    display: flex;
    flex-direction: column;
    align-self: center;
}

.step_indicator_info p {
    line-height: 1;
}

.step_icon {
    background: transparent;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid #dadce0;
    width: 37px;
    height: 37px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6c7784;
}

.step_indicator:before {
    position: absolute;
    width: 1px;
    height: 18px;
    background: #dadce0;
    content: "";
    left: 18px;
    top: -22px;
}

.input_container select {
    outline: none;
    border: 1px solid #dadce0;
    border-radius: 8px;
    height: 36px;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 4%);
    width: 100%;
    padding: 2px 10px 0 10px;
    color: #374049;
    transition: 0.2s all ease;
}

.add_new_api_container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.card.add_api_card h2 {
    margin: 0;
    line-height: 1;
    font-weight: 500;
    font-size: 20px;
    color: #374049;
    margin-bottom: 25px;
}

.card.add_api_card .input_container + .input_container {
    margin-bottom: 25px;
}

.add_new_api_container .btn.btn_link {text-wrap: nowrap;position: relative;}

.add_api_action_container {
    display: flex;
    align-self: flex-end;
}

.card.add_api_card .input_container:last-child {
    margin-bottom: 0;
}

.btn.btn_icon.btn_indicator {
    margin-right: 12px;
    padding: 0;
}

.input_container_inside_flex {
    display: flex;
    align-items: center;
}

.btn.btn_link span {
    flex-wrap: nowrap;
    width: 100%;
}

.add_new_api_container .btn.btn_w_icon svg {
    top: 8px;
}

.add_new_api_container .btn.btn_w_icon {
    padding-left: 38px;
    margin-left: 12px;
}

.btn_link svg {
    fill: #2139da;
}

.btn_link span {
    color: #2139da;
}

.btn_link {
    border-color: transparent;
    box-shadow: none;
    background: transparent;
}

.btn.btn_link:hover {
    box-shadow: none;
    border-color: transparent;
    background: rgb(33 57 218 / 15%);
}

.add_new_api_container > .btn_primary {margin-left: auto;min-width: 120px;}


.add_api_action_container > .btn_primary {
    padding: 0 40px;
}

.card.add_api_card .input_radio_container {
    width: 100%;
}

textarea {
    outline: none;
    border: 1px solid #dadce0;
    border-radius: 8px;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 4%);
    width: 100%;
    padding: 7px 10px 7px 10px;
    color: #374049;
    transition: 0.2s all ease;
    height: 96px;
    resize: none;
}

.input_checkbox_container.input_radio_container .input_radio {
    border-radius: 100px;
}

.input_checkbox_container.input_radio_container .input_radio.active {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input_checkbox_container.input_radio_container .input_radio.active:before {
    content: "";
    height: 10px;
    width: 10px;
    background: #2139da;
    position: absolute;
    border-radius: 20px;
}

.inputs_flexbox {
    display: flex;
}

.inputs_flexbox > .input_container {
    width: 100%;
}

.radio_items_container {
    display: flex;
}

.add_api_footer_action {
    display: flex;
    justify-content: space-between;
}

.add_api_footer_action .btn {
    min-width: 120px;
}

.card.add_api_card h3 {
    margin: 0;
    line-height: 1;
    font-weight: 500;
    font-size: 16px;
    color: #374049;
    margin-bottom: 16px;
}

.add_api_checkbox_container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.add_api_checkbox_container .input_checkbox_container {
        min-width: calc((100% - 20px) / 3);
        margin-bottom: 8px;
        height: 30px;
}

.add_api_checkbox_container:last-child {
    margin-bottom: 0;
}

.add_new_api_plans_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.add_new_api_plans_flex .card.add_api_card {flex: 0 0 calc((100% - 25px) / 2);}

.add_new_api_plans_flex .card.add_api_card:nth-child(2n+1) {
    margin-right: 25px;
}

.input_container_inside_flex > span:last-child {
    color: #1a1f30;
    padding-left: 10px;
    font-size: 14px;
}

.add_api_endpoint_flex {
    display: flex;
}

.add_api_endpoint_flex .input_container:last-child {
    margin-left: 25px;
    width: 100%;
}

.add_api_endpoint_flex .input_container:first-child {
    flex: 0 0 120px;
}

.add_api_parameters_container {
    background: #f9fbfc;
    border: 1px solid #dadce0;
    border-radius: 8px;
    box-shadow: inset 0 0 5px 1px rgba(60,64,67,.03);
    padding: 25px;
}

.add_api_parameters_container h4 {
    margin: 0 0 18px 0;
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    color: #374049;
}

.add_api_param_flex {
    display: flex;
    justify-content: space-between;
}

.add_api_param_flex > * {
    flex: 0 0 calc((100% - 75px) / 4);
    margin-bottom: 0 !important;
}

.add_api_param_flex .input_container label {
    height: 12px;
}

.add_api_param_flex .input_checkbox_container {
    margin-bottom: 0;
}

.add_api_parameters_container + .btn.btn_link {
    margin-top: 15px;
    text-align: center;
}

.add_api_parameters_container + .add_api_parameters_container {
    margin-top: 25px;
}

.input_container_inside_flex .btn.btn_error {
    width: 96px;
    margin-left: 18px;
}

.btn_error.btn_semi_primary span {
    color: #f34343;
}

.add_new_api_container > .btn.btn_link {
    margin-bottom: 25px;
    text-align: center;
    border-color: #dadce0;
    background: transparent;
}

.add_new_api_container > .btn.btn_link span {
    color: #6c7784;
}

.add_api_footer_action .btn.btn_icon.btn_indicator {
    margin-left: auto;
    min-width: initial;
}