.user_link {
    display: flex;
    align-items: center;
    height: 38px;
    width: 38px;
    border-radius: 38px;
    cursor: pointer;
    justify-content: center;
}

.user_link svg {
    height: 30px;
    fill: rgb(255 255 255 / 90%);
}

.user_link:hover {
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
}

.user_link.active {
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border: 1px solid rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
}

.nav_icons {
    position: absolute;
    right: 0;
    display: flex;
}

a.user_link {
    margin-left: 10px;
}