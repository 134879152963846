.sandbox_api_list_title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.sandbox_api_list_title h2 {
    font-weight: 500;
    font-size: 16px;
    color: #1a1f30;
    margin: 0 auto 0 0;
    line-height: 1;
}

.sandbox_api_list_title span {
    padding: 1px 8px;
    border-radius: 4px;
    background: rgb(33 57 218 / 10%);
    color: #2139da;
    margin-left: 10px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    margin-top: 2px;
    text-wrap: nowrap;
}

.sandbox_api_list_endpoint_flex {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    text-decoration: none;
}

.sandbox_api_list_endpoint {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px 1px rgba(60,64,67,.03);
    display: flex;
    overflow: hidden;
    border: 1px solid #dadce0;
    transition: 0.2s all ease;
    padding: 10px;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;
}

.endpoint_icon {
    height: 20px;
    width: 20px;
    background: #2139da;
    border-radius: 4px;
    margin-right: 10px;
}

.sandbox_api_list_endpoint h3 {
    margin: 0 0 0 0;
    color: #6c7784;
    font-weight: 400;
    line-height: 1.2;
    font-size: 13px;
}

.sandbox_api_list_endpoint:last-child {
    margin-bottom: 0;
}

.sandbox_api_list_endpoint:hover {
    box-shadow: rgb(0 0 0 / 0%) 0px 7px 20px 0px, rgb(0 0 0 / 7%) 0px 3px 7px 0px;
}

.sandbox_api_list_endpoint_action h2 {
    margin: 0;
    line-height: 1;
    font-weight: 500;
    font-size: 20px;
    color: #374049;
    margin-bottom: 12px;
}

.sandbox_api_list_endpoint_action p {
    font-size: 13px;
    margin-bottom: 25px;
}

.sandbox_api_list_endpoint_params .input_label_flex span {
    margin-left: 8px;
    font-size: 11px;
    background: rgb(238 240 244);
    width: auto;
    padding: 0 7px;
    height: 20px;
    border-radius: 4px;
    color: #8b959e;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.sandbox_api_list_endpoint_params .input_label_flex span.active {
    background: rgb(33 57 218 / 20%);
    color: #2139da;
}

.white_section {
    background: #fff;
    box-shadow: 0px 1px 8px rgb(0 0 0 / 10%);
}

.white_section .wrapper {
    padding: 50px 0;
}
.two_side_input:first-child {
    padding-top: 0;
    border-top: 0;
}

.input_container.input_container_prefix {
    flex-direction: row;
    border-radius: 7px;
}

.input_container.input_container_prefix span.sandbox_dev_method {
    position: absolute;
    padding: 1px 8px;
    border-radius: 4px;
    background: #d7f7c2;
    color: #006908;
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    left: 8px;
    top: -1px;
}

.input_container.input_container_prefix span.sandbox_dev_method + input[type="text"] {
    padding-left: 66px;
}

.input_two_container {
    display: flex;
}

.input_two_container input[type="text"]:first-child {
    margin-right: 16px;
}

.sandbox_dev_wrapper .input_two_container input[type="text"]:first-child {
    width: 40%;
}

.input_container input[type="text"]::-webkit-input-placeholder {
    color: #8b959e;
    font-size: 13px;
    font-weight: 300;
}
  
.input_container input[type="text"]:-ms-input-placeholder {
    color: #8b959e;
    font-size: 13px;
    font-weight: 300;
}
  
.input_container input[type="text"]::placeholder {
    color: #8b959e;
    font-size: 13px;
    font-weight: 300;
}

.sandbox_dev_wrapper .btn.btn_link.btn_w_icon {
    margin-top: -10px;
    margin-bottom: 17px;
}

.input_two_container + .input_two_container {
    margin-top: 14px;
}

.input_container + .input_container .input_two_container:first-child {
    margin-top: -8px;
}

.flex_left_side.sandbox_api_list_endpoint_action {
    max-width: 50%;
}

.flex_left_side.sandbox_api_list_endpoint_action .api_code_background > span code,
.wrapper.sandbox_dev_wrapper code {
    width: initial;
    max-width: 100%;
}

.wrapper.sandbox_dev_wrapper .api_code_background {
    margin-top: 25px;
    height: initial;
}

.wrapper.sandbox_dev_wrapper h3 {
    flex-basis: initial;
    margin: 0;
    line-height: 1;
    font-weight: 500;
    font-size: 21px;
    color: #374049;
    margin-bottom: 25px;
    margin-top: 25px;
    text-transform: capitalize;
    letter-spacing: initial;
    border-top: 1px solid #dadce0;
    padding-top: 25px;
}

.wrapper.sandbox_dev_wrapper code span {
    padding: 1px 4px !important;
}

.flex_left_side.sandbox_api_list_endpoint_action .btn.btn_primary.btn_outline {
    background: transparent;
    width: 230px;
    margin-bottom: 25px;
}

.flex_left_side.sandbox_api_list_endpoint_action .btn.btn_primary.btn_outline span {
    color: #2139da;
}

.flex_left_side.sandbox_api_list_endpoint_action .btn.btn_primary.btn_outline svg {
    fill: #2139da;
    height: 12px;
    width: 12px;
    margin-right: 5px;
}

.sandbox_api_list_endpoint_submit {
    display: flex;
}

.sandbox_api_list_endpoint_submit .btn.btn_primary:last-child {
    width: 100%;
}

.flex_left_side.sandbox_api_list_endpoint_action .btn.btn_indicator.btn_primary.btn_error {
    margin-bottom: 20px;
    box-shadow: none;
    cursor: initial;
}

.btn.btn_indicator.btn_primary.btn_error svg + span {
    color: #f34343;
    margin-left: 10px;
}