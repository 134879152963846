.dashboard_cards_container {
    display: flex;
    margin-top: -125px;
    z-index: 1;
    position: relative;
}

.dashboard_main_card {
    flex: 0 0 380px;
    box-shadow: rgb(0 0 0 / 3%) 0px 7px 30px 0px;
    border-radius: 8px;
}

.dashboard_side_card {
    margin-left: 30px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.dashboard_card_header span {
    color: rgb(255 255 255 / 90%);
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
}

.dashboard_card_header {
    padding: 10px 10px 16px 18px;
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.14));
    border: 1px solid rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
    display: flex;
    align-items: center;
    height: 52px;
}

.dashboard_calendar_header {
    padding: 10px 18px 16px 18px;
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.14));
    border: 1px solid rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
    display: flex;
    align-items: center;
    height: 52px;
}

span.calendar_btn {
    border-radius: 24px;
    -webkit-backdrop-filter: blur(0.1px);
    backdrop-filter: blur(0.1px);
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

span.calendar_title {
    color: rgb(255 255 255 / 90%);
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    margin: 0 auto;
    padding: 8px 12px;
    border-radius: 6px;
    cursor: pointer;
}

span.calendar_btn svg {
    fill: rgb(255 255 255 / 70%);
    height: 12px;
    color: red;
    width: 12px;
}

.title_search_info h6 {
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: rgb(255 255 255 / 90%);
    margin-bottom: 10px;
}

span.calendar_title:hover {
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
}

.dashboard_card_list {
    margin-top: -6px;
    position: relative;
    z-index: 1;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px 1px rgba(60,64,67,.03);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid #dadce0;
    padding-bottom: 7px;
}

.dashboard_card_item {
    height: 160px;
    border-top: 1px solid #dadce0;
}

.dashboard_card_item:first-child {
    border-top: 0;
}

.dashboard_card_wrapper {
    margin: 10px;
}

.dashboard_card_info h2 {
    margin: 0 0 0 0;
    color: #414758;
    font-weight: 600;
    line-height: 1;
    font-size: 15px;
}

.dashboard_card_info {
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid transparent;
}

.dashboard_card_info h5 {
    background: rgb(33 57 218 / 10%);
    border-radius: 4px;
    padding: 0 8px;
    display: inline-flex;
    margin-bottom: 0;
    color: #2139da;
    font-weight: 600;
    font-size: 11px;
    align-items: center;
    text-transform: uppercase;
    margin-top: 6px;
    letter-spacing: 0.8px;
    height: 18px;
    margin-left: 0;
}

.dashboard_card_title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.dashboard_card_info p {
    margin-top: 8px;
    margin-bottom: 12px;
}


.card_bar {
    background: rgb(238 240 244);
    height: 9px;
    border-radius: 10px;
    margin-bottom: 5px;
    border: 1px solid #dadce0;
    display: flex;
    overflow: hidden;
}

.card_bar_fill {
    width: 20%;
    background: #2139da;
    border-radius: 10px;
}

.dashboard_card_item:hover {
    border-color: rgb(33 57 218 / 15%);
    background-image: linear-gradient(90deg, rgb(33 57 218 / 5%), #f9fbfc 60%);
}

.dashboard_card_item:hover + .dashboard_card_item {
    border-bottom: 1px solid rgb(33 57 218 / 15%);
}

.dashboard_card_call {
    display: flex;
    justify-content: space-between;
    color: #6c7784;
    font-size: 12px;
    font-weight: 400;
}

.dashboard_calendar_body {
    margin-top: -6px;
    position: relative;
    z-index: 1;
    background: #fff;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 3%) 0px 7px 30px 0px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid #dadce0;
    padding: 10px 18px;
}

.dashboard_calendar_subheader {
    display: flex;
    font-size: 13px;
    color: #6c7784;
    margin-bottom: 6px;
}

.dashboard_calendar_subheader span {
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    width: calc((100% - 38px) / 7);
    justify-content: center;
    margin-right: 6px;
}

.dashboard_calendar_items {
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
    color: #1a1f30;
    font-weight: 500;
}

.dashboard_calendar_items span {
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    width: calc((100% - 38px) / 7);
    justify-content: center;
    cursor: pointer;
    margin-bottom: 6px;
    margin-right: 6px;
}

.dashboard_calendar_items span:hover {
    background: rgb(238 240 244);
}

.dashboard_calendar_items span.disabled {
    color: #8697a3;
    font-weight: 400;
}

.dashboard_calendar_items span:nth-child(7n) {
    margin-right: 0;
}

.dashboard_calendar_items span.active {
    background: #2139da;
    color: #fff;
    background-image: linear-gradient(80deg, #2017b1, #3545d9);
    opacity: 0.8;
}

.dashboard_calendar {
    flex: 0 0 300px;
    margin-left: 30px;
}

.dashboard_notif_card {
    width: calc(100% - 330px);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    border: 1px solid #dadce0;
    box-shadow: rgb(0 0 0 / 3%) 0px 7px 30px 0px;
    height: 284px;
    background: #fff;
    padding: 18px;
}

.dashboard_notif_card h2, .dashboard_card_table h2 {
    font-weight: 500;
    font-size: 18px;
    color: #1a1f30;
    margin: 0 0 15px 0;
    line-height: 1;
}

#called_api_graph {
    height: 100%;
    display: flex;
    border-top: 1px solid #eceff1;
    border-bottom: 1px solid #eceff1;
    margin-bottom: 25px;
    margin-top: 10px;
    position: relative;
}

#called_api_graph .x_axis {
    display: flex;
    justify-content: space-between;
    color: #6c7784;
    font-weight: 300;
    font-size: 12px;
    width: 100%;
    height: 12px;
    position: absolute;
    bottom: 0;
}

.background_lines_vert {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.background_lines_vert span {
    width: 1px;
    background: #eceff1;
    position: relative;
    height: 100%;
}

.dashboard_card_table {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    border: 1px solid #dadce0;
    box-shadow: rgb(0 0 0 / 3%) 0px 7px 30px 0px;
    background: #fff;
    padding: 18px;
    width: 100%;
    margin-top: 30px;
}

.table_element.tb_dash_error {
    color: #f44336;
    font-weight: 500;
}

tbody td:first-child .table_element.tb_dash_title {
    color: #2139da;
}

.dashboard_card_header svg {
    margin-left: auto;
    fill: rgb(255 255 255 / 90%);
    height: 29px;
    padding: 5px 0;
    width: 29px;
    border-radius: 3px;
    cursor: pointer;
}

.dashboard_card_header svg:hover {background: rgb(255 255 255 / 15%);}

.title_search_navigate {
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 34px;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 4%);
    background: #fff;
    color: #2139da;
    font-weight: 500;
    font-size: 14px;
    width: 290px;
    margin-left: 20px;
    text-decoration: none;
}