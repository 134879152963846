@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500&family=Poppins:wght@200;300;400;500;600;700&display=swap');
* {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

html, body {
    height: 100%;
    width: 100%;
    margin: 0;
}

.auth_container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px;
}

.auth_side {
    position: fixed;
    background: #2139da;
    width: calc((100% - 75px) / 2);
    height: calc(100% - 50px);
    top: 25px;
    left: 25px;
    border-radius: 16px;
    overflow: hidden;
    transition: all ease 0.5s;
    z-index: 1;
}

.auth_form_flex {
    display: flex;
    height: 100%;
    justify-content: space-between;
}

.login_form, .register_form {
    flex: 0 0 calc((100% - 25px) / 2);
    height: 100%;
    flex-direction: column;
    max-width: 450px;
    margin: 0 auto;
    padding: 0 12.5px 0 0;
    display: flex;
}

.register_form {
    flex: 0 0 calc((100% - 25px) / 2);
    height: 100%;
    padding: 0 0 0 12.5px;
}

.auth_side_backgorund {
    position: relative;
    height: 100%;
}

.auth_side_overlay {
    z-index: 1;
    opacity: .08;
    mix-blend-mode: overlay;
    background-position: 0 0;
    background-size: auto;
    background-attachment: scroll;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    height: 100%;
}

.bg-blur-parent {
    width: 100%;
    height: 100%;
    position: relative
}

.auth_side_gradient_circle1 {
    z-index: 0;
    width: 26.04em;
    height: 26.04em;
    filter: blur(80px);
    background-color: #00d4f7;
    background-image: linear-gradient(225deg,#00d4f7,rgba(0,212,247,.2));
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: -20%;
    bottom: auto;
    left: auto;
    right: -10%
}

.auth_side_gradient_circle2 {
    z-index: 0;
    width: 52.08em;
    height: 52.08em;
    filter: blur(100px);
    background-color: #4221c5;
    background-image: linear-gradient(45deg,#4221c5,rgba(66,33,197,.2));
    border-radius: 100%;
    position: absolute;
    top: auto;
    bottom: -50%;
    left: -10%;
    right: auto
}

.auth_side_gradient_circle3 {
    z-index: 0;
    width: 26.04em;
    height: 26.04em;
    filter: blur(80px);
    background-color: #020287;
    border-radius: 100%;
    position: absolute;
    top: auto;
    bottom: -20%;
    left: 30%;
    right: auto
}

.auth_side_word {
    white-space: nowrap;
    -webkit-text-fill-color: transparent;
    mix-blend-mode: overlay;
    background-image: linear-gradient(rgba(255,255,255,.4),rgba(255,255,255,.1));
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 4.5em;
    font-weight: 700;
    line-height: 1;
    display: inline-block;
}

.auth_side_text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    display: none;
}

.auth_side_word:first-child {
    align-self: flex-end;
}

.auth_side_word:last-child {
    align-self: flex-start;
}

.auth_side_word:nth-child(2) {
    align-self: center;
}

.login_form_title h1 {
    margin: 0;
    line-height: 1;
    font-weight: 500;
    font-size: 26px;
    color: #374049;
    margin-bottom: 8px;
}

input[type="text"], input[type="password"] {
    outline: none;
    border: 1px solid #dadce0;
    border-radius: 8px;
    height: 36px;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 4%);
    width: 100%;
    padding: 2px 10px 0 10px;
    color: #374049;
    transition: 0.2s all ease;
}

input[type="text"]:hover, input[type="password"]:hover, textarea:hover {
    box-shadow: rgba(64, 68, 82, 0.15) 0px 2px 8px -4px;
    border-bottom: 1px solid hsl(220 9% 80% / 1);
}

input[type="text"]:focus, input[type="password"]:focus, textarea:focus {
    box-shadow: 0 0 0 3px rgb(179 187 242);
    border-color: #8a83e3;
}

.login_form_title p {
    margin: 0;
    font-size: 13px;
    color: #8b959e;
}

.login_form_title {
    margin-bottom: 30px;
}

.social_login {
    display: flex;
    justify-content: space-between;
}

.btn {
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border: 1px solid #dadce0;
    border-radius: 8px;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 4%);
    background: #fff;
    transition: 0.2s all ease;
    text-decoration: none;
    cursor: pointer;
}

.btn:hover {
    box-shadow: rgba(64, 68, 82, 0.15) 0px 2px 8px -4px;
    border-bottom: 1px solid hsl(220 9% 80% / 1);
}

.btn:active {
    background: #fafafb;
    border-bottom: 1px solid hsl(220 9% 80% / 1);
    box-shadow: inset 0px 3px 5px -4px rgba(64, 68, 82, 0.20);
}

.btn span {
    color: #1a1f30;
    font-size: 13px;
    line-height: 1;
    font-weight: 500;
    white-space: nowrap;
}

.btn.btn_social {
    flex: 0 0 calc((100% - 20px) / 2);
}

.or_divider {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}

.divider {
    height: 1px;
    width: 100%;
    background: #dadce0;
}

.or_divider span {
    padding: 0 10px;
    font-size: 12px;
    color: #8b959e;
}

.input_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    position: relative;
}

.input_container label {
    color: #1a1f30;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 10px;
}

.input_label_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input_label_flex span {
    color: #2139da;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 10px;
    cursor: pointer;
}

.input_checkbox_container {
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 12px 0 9px;
    border-radius: 8px;
    align-self: flex-start;
    cursor: pointer;
    margin-bottom: 25px;
    cursor: pointer;
}

.input_radio {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #dadce0;
    border-radius: 4px;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 4%);
    background: #fff;
}

.input_checkbox_container.active .input_radio {
    border-color: #2139da;
    background: #2139da;
}

.input_checkbox_container.active .input_radio svg {
    height: 18px;
    width: 18px;
    fill: #fff;
    position: relative;
    left: -1px;
    top: -1px;
}

.input_checkbox_container span {
    margin: 0;
    color: #6c7784;
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
}

.input_checkbox_container:hover {
    background: #f7f8fa;
}

.btn.btn_primary {
    background: #2139da;
    border-color: #2139da;
    border-bottom-color: #4221c5;
}

.btn.btn_primary:hover {
    background: #411cd6;
    border-color: #411cd6;
    border-bottom-color: #411cd6;
}


.btn.btn_primary span {
    color: #fff;
}

p.login_already_account {
    margin: 0;
    font-size: 13px;
    color: #8b959e;
    text-align: center;
    margin: 30px 0;
    display: none;
}

p.login_already_account span {
    color: #2139da;
    font-weight: 600;
    margin-left: 8px;
    cursor: pointer;
}

p.copyright, p.language {
    margin: 0;
}

.btn.btn_social svg {
    height: 18px;
    width: 18px;
    margin-right: 10px;
}

.logo_container svg {
    height: 64px;
}

.logo_container {
    margin-bottom: 8px;
}
.login_action {
    display: flex;
    justify-content: space-between;
}

.login_action .btn.btn_primary {
    width: 100%;
}

.login_action .input_checkbox_container ~ .btn.btn_primary {
    width: 140px;
}

.login_wrapper {
    margin: auto 0;
}

.register_form .login_form_title {
    text-align: center;
}

.input_fusion {
    display: flex;
}

.input_fusion input[type="text"] {
    width: 100%;
}

.input_fusion input[type="text"]:first-child {
    border-radius: 8px 0 0 8px;
    border-right: 0;
}

.input_fusion input[type="text"]:last-child {
    border-radius: 0 8px 8px 0;
}

.input_checkbox_container span a {
    color: #2139da;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}

.input_flex {
    display: flex;
    justify-content: space-between;
}

.input_flex .input_container {
    flex: 0 0 calc((100% - 20px) / 2);
}

.auth_side_content {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(200% + 25px);
    height: 100%;
}

.auth_side_register {
    width: calc((100% - 25px) / 2);
    position: absolute;
    left: 0;
    height: 100%;
    transition: all ease 0.5s;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

.auth_side_login {
    width: calc((100% - 25px) / 2);
    position: absolute;
    left: calc(((100% - 25px) / 2) + 25px);
    height: 100%;
    transition: all ease 0.5s;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

.auth_side.auth_side_switched {
    left: calc(((100% - 25px) / 2) + 25px);
}

.auth_side.auth_side_switched .auth_side_register {
    left: calc((((100% - 25px) / 2) + 25px) * -1);
}

.auth_side.auth_side_switched .auth_side_login {
    left: 0;
}

.login_action + .btn {
    margin-top: 20px;
}

.login_back {
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    padding: 0 16px 0 10px;
    height: 28px;
    font-size: 12px;
    font-weight: 500;
    color: #6c7784;
    margin-bottom: 20px;
    cursor: pointer;
}

.login_back svg {
    height: 14px;
    width: 18px;
    fill: #6c7784;
    margin-right: 5px;
}

.login_back:hover {
    background: rgb(238 240 244);
}

.forgot_password_wrapper {
    margin: auto 0;
    padding-bottom: 43px;
}

.auth_illustration_api_categ {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 430px;
    margin: 0 auto;
}

.auth_illu_source {
    width: 100px;
    height: 100px;
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border: 1px solid rgba(255,255,255,0.25);
    border-radius: 100px;
    backdrop-filter: blur(0.5px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth_illu_source_logo {
    height: 80px;
    width: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 110%;
    border-radius: 100%;
}

.auth_illu_categories {
    display: flex;
    margin-top: -8px;
}

.auth_illu_category {
    height: 70px;
    width: 70px;
    background-image: linear-gradient(135deg,rgba(255,255,255,.05),rgba(255,255,255,0.02));
    border: 1px solid rgba(255,255,255,0.25);
    backdrop-filter: blur(0.5px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-right: 20px;
    padding: 7px;
}

.auth_illu_category:last-child {
    margin-right: 0;
}

.auth_illu_category_item {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border: 1px solid rgba(255,255,255,0.25);
    backdrop-filter: blur(0.5px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}


.auth_illu_paths {
    height: 110px;
    position: relative;
    width: 100%;
}

.auth_illu_path_1 {
    position: absolute;
    left: 0;
    height: 100px;
    width: 50%;
    bottom: 0;
}

.auth_illu_path_1 .auth_illu_path {
    height: 75px;
    width: calc(100% - 45.5px);
    position: absolute;
    bottom: 0;
    left: 35px;
    border-radius: 10px 0 0 0;
    border-left: 1px solid #8a83e3;
    border-top: 1px solid #8a83e3;
}

.auth_illu_path_1 .auth_illu_path:after {
    height: 10px;
    width: 10px;
    content: "";
    position: absolute;
    right: -11px;
    top: -11px;
    border-radius: 0 0 10px 0;
    border-bottom: 1px solid #8a83e3;
    border-right: 1px solid #8a83e3;
}

.auth_illu_path_1.auth_illu_reverse {
    transform: rotateY(180deg);
    left: initial;
    right: 0;
    width: calc(50% - 1px);
}

.auth_illu_path_2 {
    position: absolute;
    left: 0;
    height: 100px;
    width: 50%;
    bottom: 0;
}

.auth_illu_path_2 .auth_illu_path {
    height: 40px;
    width: calc(100% - 131px);
    position: absolute;
    bottom: 0;
    left: 125px;
    border-radius: 6px 0 0 0;
    border-left: 1px solid #8a83e3;
    border-top: 1px solid #8a83e3;
}

.auth_illu_path_2 .auth_illu_path:after {
    height: 6px;
    width: 6px;
    content: "";
    position: absolute;
    right: -7px;
    top: -7px;
    border-radius: 0 0 6px 0;
    border-bottom: 1px solid #8a83e3;
    border-right: 1px solid #8a83e3;
}

.auth_illu_path_2.auth_illu_reverse {
    transform: rotateY(180deg);
    left: initial;
    right: 0;
    width: calc(50% - 1px);
}

.auth_illu_path_3 {
    height: 100%;
    width: 1px;
    background: #8a83e3;
    position: absolute;
    left: 215px;
}

.auth_side_login_content h1 {
    color: #fff;
    font-size: 21px;
    text-align: center;
    font-weight: 600;
    margin: 0 auto 0 auto;
    margin-bottom: 8px;
    max-width: 470px;
}

.auth_side_login_content > p {
    margin: 0;
    font-size: 13px;
    color: #fff;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
}

.auth_side_login_create {
    font-size: 11px;
    color: #fff;
    text-align: center;
    max-width: 400px;
    margin: 40px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth_side_login_create span {
    height: 37px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border: 1px solid #dadce0;
    border-radius: 8px;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 4%);
    background: #fff;
    color: #2139da;
    font-weight: 600;
    font-size: 14px;
    width: 110px;
    margin-left: 20px;
}

.auth_side_login_content {
    padding: 20px 0 25px 0;
}

.auth_side_login_footer {
    margin: auto 0 0 0;
    text-align: center;
    color: rgb(255 255 255 / 60%);
    font-size: 12px;
    margin-top: 25px;
    display: flex;
    justify-content: space-around;
    padding: 0 50px 15px 50px;
}

.auth_side_login_footer a {
    text-decoration: none;
    text-align: center;
    color: rgb(255 255 255 / 60%);
    font-size: 12px;
}

.password_verification_item span {
    color: #8b959e;
    font-size: 12px;
}

.password_verification_item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.password_verification_icon_wrapper {
    height: 16px;
    width: 16px;
    border-radius: 16px;
    background: #dadce0;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.password_verification {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 84px;
    margin-bottom: 15px;
}

.password_verification_icon {
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    height: 5px;
    width: 8px;
    transform: rotate(-45deg);
    position: relative;
    top: -1px;
}

.password_verification_item.password_verification_valid span {
    color: #0aae67;
    font-weight: 500;
}

.password_verification_item.password_verification_valid .password_verification_icon_wrapper {
    background: #0aae67;
}

.auth_side_register_content h2 {
    color: #fff;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    margin: 0 auto 20px auto;
    position: relative;
    flex: 0 0 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.auth_side_register_content h2 > span {
    margin: 0 auto;
    display: block;
    position: absolute;
    width: 100%;
    transition: all ease 0.3s;
    left: 0;
}

.auth_side_register_content h2 > span > span {
    max-width: 430px;
    margin: 0 auto;
    display: block;
    width: 100%;
}

.auth_side_carousel_container {
    display: flex;
    width: 56px;
    margin: 0 auto;
    justify-content: space-between;
}

.auth_side_carousel {
    height: 9px;
    width: 9px;
    background: rgb(255 255 255 / 40%);
    border-radius: 9px;
    cursor: pointer;
}

.auth_side_carousel:hover {
    background: rgb(255 255 255 / 70%);
}

.auth_side_carousel.active {
    width: 25px;
    background: rgb(255 255 255 / 90%);
}

.auth_side_register .login_back {
    color: #fff;
    margin-bottom: 0;
    position: absolute;
}

.auth_side_register .login_back svg {
    fill: #fff;
}

.auth_side_header .login_back:hover {
    background: rgb(255 255 255 / 30%);
}

.auth_side_header {
    padding: 25px;
    display: flex;
    align-items: center;
}

.auth_side_logo {
    height: 39px;
    width: 39px;
    overflow: hidden;
    margin: 0 auto;
}

.auth_side_logo img {
    display: block;
}

.auth_side_register_illustration {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.auth_side_register_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 35px;
    padding-top: 20px;
}
.auth_card {
    background: linear-gradient(115deg, rgb(238 240 244), #fff);
    width: 300px;
    padding: 12px 18px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    box-shadow: rgb(0 0 0 / 30%) 0px 7px 30px 0px, rgb(0 0 0 / 15%) 0px 3px 7px 0px;
    border: 1px solid #dadce0;
    margin: 0 auto 70px auto;
    height: 180px;
    -webkit-backdrop-filter: blur(.5px);
    backdrop-filter: blur(.5px);
}

.auth_card_header > span {
    font-size: 16px;
    font-weight: 500;
    color: #0c2444;
}

.auth_card_search {
    padding: 0 2px;
    width: 30px;
    background: rgb(238 240 244);
    height: 30px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth_card_search svg {
    width: 22px;
    height: 22px;
    fill: #6c7784;
}


.auth_card_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.auth_list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

.auth_list img {
    display: block;
    width: calc((100% - 20px) / 3);
    box-shadow: 1px solid rgb(218 220 224 / 40%);
    border-radius: 5px;
    border: 1px solid #dadce0;
    margin-bottom: 12px;
}

.auth_card > span {
    text-align: right;
    font-size: 11px;
    color: #fff;
    max-width: 400px;
    padding-top: 8px;
}

.auth_list img:nth-child(4), .auth_list img:nth-child(5), .auth_list img:nth-child(6) {
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), transparent);
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, .4));
}

.auth_list img:nth-child(7), .auth_list img:nth-child(8), .auth_list img:nth-child(9) {
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), transparent);
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, .4), transparent 55%);
    margin-bottom: 0;
}

.auth_side_illu2 {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    position: relative;
    align-items: center;
    position: absolute;
    width: 100%;
    transition: all ease 0.3s;
    left: 0;
}

.auth_plan_free {
    height: 270px;
    width: 200px;
    border-radius: 10px;
    background: rgb(255 255 255 / 97%);
    box-shadow: rgb(0 0 0 / 20%) 0px 7px 30px 0px, rgb(0 0 0 / 7.5%) 0px 3px 7px 0px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 15px;
    background: linear-gradient(115deg, rgb(238 240 244), #fff);
    border: 1px solid #dadce0;
}

.auth_plan_behind, .auth_plan_second {
    height: 240px;
    width: 180px;
    position: absolute;
    margin-left: -170px;
    border-radius: 10px 0 0 10px;
    background-image: linear-gradient(135deg,rgba(255,255,255,.3),rgba(255,255,255,0.1));
    border: 1px solid rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.auth_plan_second {
    margin-left: 170px;
    border-radius: 0 10px 10px 0;
}
span.auth_plan_title {
    text-align: center;
    position: relative;
    font-weight: 600;
    font-size: 23px;
    color: #2139da;
}

span.auth_plan_price {
    position: relative;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    color: #374049;
    border-top: 1px solid #dadce0;
    border-bottom: 1px solid #dadce0;
    line-height: 1;
    padding: 12px 0;
    margin: 12px 10px;
}

span.auth_plan_price span {
    font-size: 12px;
    font-weight: 500;
}

span.auth_plan_info {
    text-align: center;
    line-height: 18px;
    font-size: 12px;
    padding: 0 20px;
    font-weight: 500;
    color: #6c7784;
}

span.auth_plan_btn {
    background: #0aae67;
    margin-top: auto;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    color: #f8f8fc;
    padding: 10px;
    border-radius: 8px;
}

span.auth_plan_s_btn {
    margin-top: auto;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    color: rgb(248 248 252 / 70%);
    padding: 10px;
    border-radius: 8px;
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border: 1px solid rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
}

span.auth_plan_s_title {
    flex: 0 0 40px;
    border-radius: 8px;
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border: 1px solid rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    opacity: 0.3;
}

span.auth_plan_s_price {
    height: 100%;
    border-radius: 8px;
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border: 1px solid rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    opacity: 0.3;
    margin-top: 15px;
    margin-bottom: 15px;
}

.auth_side_illu3 {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    height: 100%;
    padding-bottom: 50px;
    position: absolute;
    width: 100%;
    transition: all ease 0.3s;
    left: 0;
}

.auth_side_code {
    height: 210px;
    width: 330px;
    border-radius: 10px;
    background: rgb(255 255 255 / 97%);
    box-shadow: rgb(0 0 0 / 20%) 0px 7px 30px 0px, rgb(0 0 0 / 7.5%) 0px 3px 7px 0px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: linear-gradient(115deg, rgb(238 240 244), #fff);
    border: 1px solid #dadce0;
    font-size: 9.5px;
    font-weight: 500;
}

.auth_side_security {
    height: 70px;
    width: 70px;
    background-image: linear-gradient(135deg,rgba(255,255,255,.5),rgba(255,255,255,0.2));
    border: 1px solid rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 7px;
    position: absolute;
    z-index: 2;
    margin-right: 352px;
    margin-top: -32px;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
}

.auth_side_security_icon {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border: 1px solid rgba(255,255,255,0.2);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
}

.auth_side_tabs {
    height: 38px;
    width: 245px;
    background-image: linear-gradient(135deg,rgba(255,255,255,.5),rgba(255,255,255,0.2));
    border: 1px solid rgba(255,255,255,0.5);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 7px;
    position: absolute;
    z-index: 2;
    margin-right: -204px;
    margin-top: 80px;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    overflow: hidden;
}

.auth_side_code_container {
    border: 1px solid #e2e4eb;
    box-shadow: inset rgb(0 0 0 / 8%) 0px 0px 3px;
    background: rgb(238 240 244);
    height: 100%;
    border-radius: 6px;
}

.auth_side_tab {
    color: #6c7784;
    font-size: 13px;
    flex: 0 0 55px;
    font-weight: 500;
    position: relative;
    display: flex;
    justify-content: center;
}

.auth_side_tab span {
    position: absolute;
    height: 10px;
    background: rgb(33 57 218 / 80%);
    bottom: -15px;
    border-radius: 4px;
    width: 35px;
    display: none;
}

.auth_side_tab:last-child {
    color: #fff;
}

.auth_side_tab.active {
    background: #2139da;
    border-radius: 5px;
    height: 100%;
    display: flex;
    align-items: center;
    color: #fff;
    margin-right: 10px;
}

span.code_red {
    color: #e06c75;
}

span.code_purple {
    color: #a626a4;
}

span.code_blue {
    color: #4078f2;
}
span.code_green {
    color: #50a14f;
}

.auth_side_code p {
    margin: 0 0 2px 0;
}

.auth_side_code p:nth-child(2), .auth_side_code p:nth-child(3), .auth_side_code p:nth-child(8), .auth_side_code p:nth-child(11), .auth_side_code p:nth-child(12) {
    margin-left: 12px;
}

.auth_side_code p:nth-child(4), .auth_side_code p:nth-child(7) {
    margin-left: 24px;
}

.auth_side_code p:nth-child(5), .auth_side_code p:nth-child(6) {
    margin-left: 36px;
}
.auth_side_illu1 {
    position: absolute;
    width: 100%;
    transition: all ease 0.3s;
    left: 0;
}

.auth_side_illu_hidden_left, .auth_side_register_content h2 > span.auth_side_illu_hidden_left {
    left: -100%;
    opacity: 0;
}

.auth_side_illu_hidden_right, .auth_side_register_content h2 > span.auth_side_illu_hidden_right {
    left: 100%;
    opacity: 0;
}

.login_footer {
    display: flex;
    margin: 25px 0 15px 0;
    color: #8b959e;
    font-size: 12px;
    justify-content: space-between;
}

p.language span {
    margin: 0 6px;
    cursor: pointer;
}

p.language span:hover {
    color: #374049;
}

.login_footer {
    display: flex;
    margin: 25px 0 15px 0;
    color: #8b959e;
    font-size: 12px;
    justify-content: space-between;
}

p.language span {
    margin: 0 6px;
    cursor: pointer;
}

p.language span:hover {
    color: #374049;
}

.auth_side .login_footer {
    max-width: 450px;
    margin: 25px auto 15px auto;
    width: 100%;
    color: rgb(255 255 255 / 60%);
    padding-left: 12.5px;
}

.auth_side p.language span:hover {
    color: #fff;
}

.auth_illu_category span {
    color: #ffffff;
    font-size: 10px;
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: 10px;
    position: absolute;
    bottom: -47px;
    opacity: 0.7;
    letter-spacing: 0.5px;
    text-align: center;
    height: 30px;
    display: flex;
    align-items: center;
}

.auth_side p.copyright, .auth_side p.language {
    color: rgb(255 255 255 / 60%);
}

.btn.btn_primary.btn_loading svg {
    height: 30px;
    width: 30px;
}

.btn.btn_primary.btn_loading {
    background: rgb(99 116 229);
    border-color: rgb(99 116 229);
    cursor: wait;
}

.btn.btn_primary.btn_success {
    background: #0aae67;
    border-color: #0aae67;
}


.btn.btn_primary.btn_error {
    background: #f34343;
    border-color: #f34343;
}

.login_action .btn.btn_icon {
    margin-left: auto;
    margin-right: 12px;
}

.btn.btn_indicator.btn_primary.btn_loading {
    background: #e5e7fb;
    border-color: #e5e7fb;
}

.btn.btn_indicator.btn_primary.btn_loading svg {
    fill: #2139da;
}

.btn.btn_indicator.btn_primary.btn_success {
    background: #c9edde;
    border-color: #c9edde;
}

.btn.btn_indicator.btn_primary.btn_success svg {
    fill: #0aae67;
}

.btn.btn_indicator.btn_primary.btn_error {
    background: #fdd9d9;
    border-color: #fdd9d9;
}

.btn.btn_indicator.btn_primary.btn_error svg {
    fill: #f34343;
    height: 20px;
    width: 20px;
}

.input_container.input_container_error input[type="text"],
.input_container.input_container_error input[type="password"],
.input_container.input_container_error textarea {
    border: 1px solid #e9a7a7;
    box-shadow: 0px 1px 1px rgb(203 48 48 / 4%);
}

.input_container.input_container_error input[type="text"]:hover,
.input_container.input_container_error input[type="password"]:hover,
.input_container.input_container_error textarea:hover {
    box-shadow: rgb(82 64 64 / 15%) 0px 2px 8px -4px;
    border-bottom: 1px solid #dd7878;
}

.input_container.input_container_error input[type="text"]:focus,
.input_container.input_container_error input[type="password"]:focus,
.input_container.input_container_error textarea:focus {
    box-shadow: 0 0 0 3px rgb(252 208 208);
    border-color: #cb8484;
}

input[type="text"][disabled], input[type="password"][disabled] {
    background: rgba(239, 239, 239, 0.3);
    color: #8b959e;
}

span.input_info {
    color: #8b959e;
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    margin-bottom: 10px;
    margin-left: auto;
}

span.error_info.input_info {
    color: #f34343;
}

.label_flex {
    display: flex;
}

.input_checkbox_container.error .input_radio {
    border: 1px solid #e9a7a7;
}

.login_form_title p span {
    color: #6c7784;
    font-weight: 500;
}

.vi__character {
    outline: none;
    border: 2px solid rgb(33 57 218 / 70%);
    border-radius: 8px;
    width: 100%;
    padding: 3px 10px 0 10px;
    color: #374049;
    transition: 0.2s all ease;
    height: 60px;
    /* box-shadow: 0 0 0 3px rgb(179 187 242); */
    font-size: 28px;
    flex: 0 0 calc((100% - 120px) / 6);
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vi__character--inactive {
    background: #fff;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 4%);
    border: 1px solid #dadce0;
}


.vi__wrapper {
    width: 100%;
}

.vi__container {
    width: 100%;
    gap: 0;
    justify-content: space-between;
    height: 60px;
}

.key_input_container_0 .vi__character:nth-child(1),
.key_input_container_1 .vi__character:nth-child(2),
.key_input_container_2 .vi__character:nth-child(3),
.key_input_container_3 .vi__character:nth-child(4),
.key_input_container_4 .vi__character:nth-child(5),
.key_input_container_5 .vi__character:nth-child(6) {
    box-shadow: 0 0 0 3px rgb(179 187 242);
    border: 1px solid #8a83e3;
}

.input_container_error .vi__character--inactive {border-color: #e9a7a7;}

.login_wrapper.register_wrapper_success .login_form_title h1 {
    margin-bottom: 16px;
}
.login_wrapper.register_wrapper_success .login_form_title p {
    max-width: 290px;
    margin: auto;
}

.login_wrapper_icon {
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border: 1px solid #dadce0;
    border-radius: 8px;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 4%);
    background: #fff;
    transition: 0.2s all ease;
    text-decoration: none;
    margin: 0 auto 20px auto;
}

.login_wrapper_icon svg {
    fill: #6c7784;
}

.api_incoive_popup_container > p {
    margin-bottom: 16px;
}

.api_incoive_popup_container h1 {margin: 0 0 0 0;color: #414758;font-weight: 500;line-height: 1.2;font-size: 16px;margin-bottom: 8px;}

.api_invoice_popup_icon {
    height: 36px;
    background: rgb(10 174 103 / 10%);
    width: 36px;
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.api_invoice_popup_icon svg {
    fill: #0aae67;
}

.auth_illu_category_item svg {
    height: 42px;
}

.auth_side_illu {
    height: 100%;
}

.logo_animation {
    height: 100%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}