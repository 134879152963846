.card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px 1px rgba(60,64,67,.03);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid #dadce0;
    transition: 0.2s all ease;
}

h4 {
    margin: 0 0 0 0;
    color: #414758;
    font-weight: 600;
    line-height: 1.2;
    font-size: 15px;
    margin-bottom: 12px;
}

p {
    margin: 0;
    color: #8b959e;
    font-size: 12px;
    line-height: 1.4;
    font-weight: 400;
}

.card_body {
    padding: 25px 25px 0 25px;
    height: 100%;
    position: relative;
}

span.api_icon {
    height: 30px;
    flex: 0 0 30px;
    background: #2139da;
    border-radius: 5px;
    margin-right: 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: contain;
}

span.api_icon svg {
    fill: #fff;
    height: 21px;
    width: 21px;
}

.card_flex {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}


.body {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.wrapper {
    max-width: 1146px;
    margin: 0 auto;
    width: 100%;
}

.navbar {
    /* height: 178px; */
    background: #2139da;
    /* overflow: hidden; */
    position: relative;
    box-shadow: 0px 1px 8px rgb(0 0 0 / 10%);
    z-index: 1;
}

.navbar .wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;
    z-index: 2;
    height: 68px;
    border-bottom: 1px solid #ffffff4a;
}

.navbar .logo_container {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    position: absolute;
}

.navbar .logo_container svg {
    height: 40px;
    margin-left: -2px;
    margin-right: -2px;
}

.nav_link a {
    text-decoration: none;
}

.nav_link span {
    color: rgb(255 255 255 / 90%);
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    cursor: pointer;
    padding: 8px 14px;
    border-radius: 6px;
    border: 1px solid transparent;
    text-align: center;
}

.nav_link span:last-child {
    margin-right: 0;
}

.nav_link {
    margin: 0 auto;
    width: 100%;
    /* max-width: 560px; */
    max-width: 450px;
    justify-content: space-between;
    display: flex;
}

.navbar .auth_side_backgorund {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
}

.nav_link span:hover {
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
}

.nav_link span.active {
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border: 1px solid rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
}

.navbar .auth_side_gradient_circle1 {
    background-color: #8a83e3;
    background-image: linear-gradient(225deg,#8a83e3,rgb(138 131 227 / 20%));
    opacity: 0.3;
}

.navbar .auth_side_gradient_circle3 {
    opacity: 0.7;
    width: 46.04em;
    height: 16.04em;
}

span.api_icon:before {
    content: "";
    border: 1px solid rgb(33 57 218 / 60%);
    width: 34px;
    height: 34px;
    display: block;
    position: absolute;
    top: -3px;
    left: -3px;
    border-radius: 7px;
    opacity: 0;
}

.body > .wrapper {
    padding: 50px 0;
}

.card_list {
    display: flex;
    flex-wrap: wrap;
}

.title_search {
    display: flex;
    place-content: space-between;
    margin-bottom: 25px;
    position: relative;
    z-index: 2;
    max-width: 1146px;
    margin: 0 auto;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 0;
    min-height: 218px;
}

.title_search h1 {
    margin: 0;
    line-height: 1;
    font-weight: 600;
    font-size: 30px;
    color: #fff;
}

.title_search .input_container {
    margin-bottom: 0;
    width: 260px;
    margin-right: 10px;
}

.api_card_list .card_container {
    width: 306px;
    flex: 0 0 calc((100% - 50px) / 3);
    margin-bottom: 25px;
    margin-right: 25px;
}

.api_card_list .card_container:nth-child(3n) {
    margin-right: 0;
}

.title_search input[type="text"] {
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border: 1px solid rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    background-color: transparent;
    color: #fff;
}

.title_search input[type="text"]:focus {
    background-image: linear-gradient(135deg,rgba(255,255,255,.25),rgba(255,255,255,0.16));
    border: 1px solid rgba(255,255,255,0.3);
    box-shadow: none;
}

.title_search_btn {
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border: 1px solid rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    margin-left: 12px;
    width: 42px;
    border-radius: 8px;
    cursor: pointer;
}

.title_search_action {
    display: flex;
    position: relative;
    flex-direction: column;
}

.filter {
    padding: 25px;
    margin-bottom: 50px;
    background: #f9fbfc;
    border: 1px solid #dadce0;
    border-radius: 8px;
}

.filter_container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(0 0 0 / 50%);
    z-index: 2;
    /* background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06)); */
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
}

.filter_nav {
    height: 100%;
    width: 375px;
    background: white;
    margin-left: auto;
}

.filter_dropdown {
    width: 100%;
    position: absolute;
    top: 54px;
    height: 260px;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12), 0 2px 4px -1px rgba(0,0,0,.2);
    display: flex;
    flex-direction: column;
    border-radius: 8px;
}

.filter_tabs {
    flex: 0 0 40px;
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border: 1px solid rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    background-color: #ffffff2b;
    display: flex;
    align-items: flex-end;
    padding: 0 5px;
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
}

.filter_body {
    border: 1px solid #dadce0;
    background: #fff;
    height: 100%;
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    -webkit-backdrop-filter: blur(45px);
    backdrop-filter: blur(45px);
    background-color: transparent;    border-top: 0;
    overflow: hidden;
    padding: 0 15px;
    border-radius: 0 0 8px 8px;
}

.filter_tabs span {
    color: rgb(255 255 255 / 90%);
    font-size: 13px;
    line-height: 1;
    font-weight: 500;
    padding: 8px 14px;
    position: relative;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    flex: 0 0 95px;
    justify-content: center;
}

.filter_tabs span.active {
    color: rgb(255 255 255 / 100%);
}

.filter_tabs span.active:before {
    content: "";
    width: calc(100% - 20px);
    height: 4px;
    position: absolute;
    bottom: 0px;
    left: 10px;
    border-radius: 4px 4px 0 0;
    background-color: rgba(255,255,255,.4);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
}

.filter_tabs_bar {
    display: flex;
    align-items: flex-end;
    padding: 0 5px;
}

.filter_tabs_bar span.active {
    align-items: center;
    flex: 0 0 65px;
    justify-content: center;
    margin: -7px 15px 0 15px;
    box-shadow: 0 0 12px 0 #2139da;
    height: 7px;
}

.filter_body .input_checkbox_container span {
    color: #1a1f30;
    font-weight: 500;
    font-size: 13px;
}

.filter_body .input_checkbox_container {
    height: 30px;
    margin-bottom: 4px;
    border-radius: 4px;
    margin-right: 10px;
}

.filter_body .input_checkbox_container:hover {
    background: rgb(198 196 227 / 30%);
    backdrop-filter: blur(.5px);
}

.filter_check_list {
    height: 100%;
    overflow: auto;
    padding: 15px 0;
}

.filter_body .input_checkbox_container:last-child {
    margin-bottom: 0;
}

.filter_check_list::-webkit-scrollbar {
  width: 10px;
}

.filter_check_list::-webkit-scrollbar-track {
    background: rgb(218 221 227); 
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
}
 
.filter_check_list::-webkit-scrollbar-thumb {
    background: rgb(0 0 0 / 20%); 
    border-radius: 5px;
}

.filter_check_list::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


.card_connect {
    padding: 0 10px 0 8px;
    border: 1px solid #dadce0;
    color: #1a1f30;
    font-size: 13px;
    line-height: 1;
    font-weight: 500;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 4%);
    display: flex;
    align-items: center;
    height: 28px;
    border-radius: 4px;
    margin-left: auto;
}

.title_search_info p {
    margin-top: 8px;
    color: #fff;
    font-size: 14px;
    max-width: 800px;
}

.card_connect.active {
    border: 1px solid #9bdec1;
    box-shadow: none;
    color: #0aae67;
    background: rgb(10 174 103 / 9%);
    padding: 0 10px;
}

.title_search_action_primary {
    display: flex;
}

.title_search_action_secondary {
    display: flex;
    margin-top: 12px;
}

.title_search_select {
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border: 1px solid rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    height: 42px;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 15px;
}

.title_search_select:first-child {
    margin-right: 12px;
}

.title_search_select span {
    color: rgb(255 255 255 / 60%);
    font-size: 13px;
    line-height: 1;
    font-weight: 400;
}

.card_connect svg {
    height: 17px;
    fill: #374049;
    width: 17px;
    margin-right: 5px;
    transform: rotate(45deg);
}

.btn.btn_glass {
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border-color: rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    background-color: inherit;
}

.btn.btn_glass span {
    color: rgb(255 255 255 / 99%);
}

.btn.btn_icon {
    width: 36px;
    flex: 0 0 36px;
    padding: 0;
}

.btn.btn_glass:hover {
    background-image: linear-gradient(135deg,rgba(255,255,255,.25),rgba(255,255,255,0.16));
}

.btn.btn_icon svg {
    fill: rgb(255 255 255 / 60%);
    height: 20px;
    width: 20px;
}



.input_container svg {
    position: absolute;
    z-index: 1;
    height: 20px;
    top: 9px;
    left: 10px;
    width: 20px;
}

.tabs_header_side .input_container svg {
    fill: rgb(255 255 255 / 60%);
}

.input_container svg + input[type="text"] {
    padding-left: 40px;
    padding-top: 2px;
}

.input_container svg + input[type="text"]::-webkit-input-placeholder {
    color: rgb(255 255 255 / 60%);
}
  
.input_container svg + input[type="text"]:-ms-input-placeholder {
    color: rgb(255 255 255 / 60%);
}
  
.input_container svg + input[type="text"]::placeholder {
    color: rgb(255 255 255 / 60%);
}

.btn.btn_glass.btn_icon:hover svg {
    fill: rgb(255 255 255 / 80%);
}

.card_body p {
    font-size: 12px;
}

.card_body h4 {
    font-size: 16px;
    margin-bottom: 0;
}

.tag {
    font-size: 11px;
    margin-right: 8px;
    background: rgb(33 57 218 / 8%);
    padding: 2px 8px;
    border-radius: 5px;
    margin-top: 10px;
    color: #2139da;
    font-weight: 500;

}

.card_tags {
    display: flex;
    margin-bottom: 12px;
    flex-wrap: wrap;
}

.tag:last-child {
    margin-right: 0;
}

.documentation .btn.btn_w_icon svg {
    height: 17px;
    fill: #374049;
    width: 17px;
    margin-right: 5px;
    transform: rotate(45deg);
}


.card_action {
    display: flex;
    padding: 20px 25px 25px 25px;
}

.card_action.card_action_active {
    align-items: center;
}

.card_action.card_action_plans {
    justify-content: flex-end;
}

.card_action.card_action_plans .btn.btn_icon {
    margin-right: 15px;
    border: none;
    box-shadow: none;
}

.card_action.card_action_plans .btn.btn_icon + .btn,
.btn.api_card_btn {
    width: 100%;
    border-color: #c4c2e3;
    text-decoration: none;
}

.card_action.card_action_plans .btn.btn_icon svg {
    fill: #8697a3;
}

.card_action.card_action_plans .btn.btn_icon:hover {
    background: rgb(238 240 244);
}

p.card_labels {
    margin: 7px 0;
    display: flex;
}

p.card_labels > span {
    margin-right: 10px;
    width: 75px;
}

p.card_labels > span + .card_label_values {
    color: #374049;
}

.card_action.card_action_plans .btn.btn_icon + .btn span,
.btn.api_card_btn span {
    color: #2139da;
}

.api_card_list .card:hover {
    box-shadow: rgb(0 0 0 / 0%) 0px 7px 20px 0px, rgb(0 0 0 / 7%) 0px 3px 7px 0px;
}

.api_card_list .card:hover .card_action.card_action_plans .btn.btn_icon + .btn,
.card:hover .btn.api_card_btn {
    background: #2139da;
    border-color: #2139da;
}

.api_card_list .card:hover .card_action.card_action_plans .btn.btn_icon + .btn span,
.card:hover .btn.api_card_btn span {
    color: #fff;
}

.card_action.card_action_active span {
    font-size: 12px;
    font-weight: 500;
    color: #0aae67;
    margin-right: auto;
    background: rgb(10 174 103 / 10%);
    padding: 6px 12px;
    border-radius: 5px;
}

.card_action.card_action_active svg {
    fill: #374049;
}

.api_card_skeleton {
    width: 306px;
    flex: 0 0 calc((100% - 50px) / 3);
    margin-bottom: 25px;
    margin-right: 25px;
    text-decoration: none;
    height: 250px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: #f0f1f3;
    border-radius: 8px;
}

.api_card_skeleton:nth-child(3n) {
    margin-right: 0;
}

.api_filter_list .input_checkbox_container {
    margin-bottom: 10px;
    height: 28px;
    padding-left: 5px;
    border-radius: 5px;
}

.api_filter_list {
    display: flex;
    flex-wrap: wrap;
}

.api_filter_container {
    display: flex;
    justify-content: space-between;
    height: 0px;
    overflow: hidden;
    opacity: 0;
    transition: 0.5s all ease;
}

.api_filter_item:nth-child(2) {
    margin-left: 25px;
}

.api_filter_item h2 {
    margin: 0;
    line-height: 1;
    font-weight: 500;
    font-size: 16px;
    color: #374049;
    margin-bottom: 16px;
}
.api_filter_item:nth-child(1) .api_filter_list .input_checkbox_container {
    min-width: calc((100% - 20px) / 3);
}
.api_filter_item:nth-child(2) .api_filter_list .input_checkbox_container {
    min-width: calc((100% - 10px) / 2);
}
.api_filter_item:nth-child(3) .api_filter_list .input_checkbox_container {
    min-width: calc(100% / 1);
}

.api_filter_divider {
    flex: 0 0 1px;
    background: #dadce0;
    height: 150px;
    align-self: center;
}

.api_filter_item {
    background: rgb(248 249 251);
    padding: 25px 25px 15px 25px;
    border: 1px solid #dadce0;
    border-radius: 8px;
    box-shadow: inset 0 1px 5px 1px rgba(60,64,67,.03);
    margin-bottom: 25px;
}

.api_filter_list .input_checkbox_container:hover {
    background: #eceff1;
}
.api_filter_item:nth-child(1) .api_filter_list .input_checkbox_container:nth-child(3n-1) {
    margin: 0 10px;
}
.api_filter_item:nth-child(2) .api_filter_list .input_checkbox_container:nth-child(2n) {
    margin-left: 10px;
}

.api_filter_container.active {
    height: 251px;
    opacity: 1;
}

.card_list.api_card_list .card_container .card {
    height: 100%;
}