.coming_soon_container {
    align-self: center;
    margin: auto;
    padding-bottom: 60px;
}

.coming_soon_body {
    display: flex;
    flex-direction: column;
}

.coming_soon_container .history_empty_container {
    align-items: center;
}

.coming_soon_container p {
    max-width: 400px;
    text-align: center;
    line-height: 1.6;
}

.coming_soon_container h3 {
    font-size: 22px;
    margin-bottom: 12px;
}

.coming_soon_container a.btn.btn_primary {
    margin-top: 12px;
}