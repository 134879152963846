.body > .wrapper > h1.wrapper_titler, .wrapper_titler {
    margin-bottom: 8px;
}

h1.wrapper_titler + p {
    margin: 0 0 25px 0;
    font-size: 13px;
    color: #8b959e;
}

h1.wrapper_titler + p.max_length {
    max-width: 600px;
}

.two_side_input {
    display: flex;
    border-top: 1px solid #dadce0;
    padding-top: 25px;
}

.label_side {
    flex: 0 0 400px;
    padding-right: 20px;
}

.input_side {
    width: 100%;
    max-width: 440px;
}

.two_side_input label {
    color: #1a1f30;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
}

.two_side_submit {
    display: flex;
    border-top: 1px solid #dadce0;
    padding-top: 25px;
    justify-content: flex-end;
}

.two_side_input .input_container:first-child span:first-child {
    margin-top: 8px;
}

.input_container.input_address_no {
    flex: 0 0 55px;
}

.input_flex.input_flex_street .input_container {
    flex: 0 0 60px;
    margin-right: 20px;
}

.input_flex.input_flex_street .input_container:last-child {
    width: 100%;
    flex: initial;
    margin-right: 0;
}

.input_flex.input_flex_city_zip .input_container:first-child {
    flex: 0 0 150px;
    margin-right: 20px;
}

.input_flex.input_flex_city_zip .input_container:last-child {
    width: 100%;
    flex: initial;
}

.input_checkbox_container label {
    font-weight: 400;
    color: #1a1f30;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 0;
    cursor: pointer;
}

.checkbox_btns {
    display: flex;
    height: 36px;
    background-image: linear-gradient(135deg,rgba(255,255,255,.05),rgba(255,255,255,0.02));
    border: 1px solid rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    border-radius: 6px;
    align-items: center;
    padding: 5px;
}

.tabs_header_side {
    margin-left: auto;
    margin-bottom: 10px;
    display: flex;
}

.checkbox_btn {
    color: rgb(255 255 255 / 90%);
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 13px;
    margin-right: 5px;
    border-radius: 4px;
    border: 1px solid transparent;
    letter-spacing: 0.3px;
    cursor: pointer;
}

.checkbox_btn.active, .checkbox_btn.active:hover {
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border-color: rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
}

.checkbox_btn:last-child {
    margin-right: 0;
}

.checkbox_btn:hover {
        background-image: linear-gradient(135deg,rgba(255,255,255,.08),rgba(255,255,255,0.04));
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
}

.title_search_img_container {
    height: 80px;
    width: 80px;
    background-image: linear-gradient(135deg,rgba(255,255,255,.05),rgba(255,255,255,0.02));
    border: 1px solid rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-right: 20px;
    padding: 7px;
}

.title_search_flex {
    display: flex;
    align-items: center;
}

.title_search_img {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border: 1px solid rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.title_search_img svg {
    fill: rgb(255 255 255 / 60%);
}

.profile_key {
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin-top: 12px;
}

span.profile_key_text {
    font-family: 'Poppins';
    color: rgb(255 255 255 / 90%);
    font-size: 12px;
}

span.profile_key_copy {
    font-family: 'Poppins';
    color: rgb(255 255 255 / 60%);
    font-size: 12px;
    padding: 0 10px;
    margin-left: 15px;
    cursor: pointer;
    border-radius: 4px;
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    border: 1px solid rgba(255,255,255,0.15);
}
span.profile_key_text span {
    margin-right: 20px;
    color: rgb(255 255 255 / 70%);
}

span.profile_key_copy:hover {
    background-image: linear-gradient(135deg,rgba(255,255,255,.25),rgba(255,255,255,0.16));
    color: rgb(255 255 255 / 80%);
}

span.profile_key_copy:active {
    color: rgb(255 255 255 / 60%);
    background-image: linear-gradient(135deg,rgba(200,200,200,.25),rgba(200,200,200,0.16));
}

.btn.btn_glass.logout_btn {
    background-image: linear-gradient(135deg,rgb(244 67 54 / 65%),rgb(244 67 54 / 46%));
    border: 1px solid rgb(154 29 20 / 55%);
    border: 1px solid rgb(242 187 193 / 40%);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    background-color: inherit;
    margin-left: 10px;
}

.btn.btn_glass.logout_btn span {
    text-shadow: 0 0 4px rgb(244 67 54 / 46%);
    font-weight: 600;
    color: #4d0a0a;
}

.btn.btn_glass.logout_btn.btn_icon svg {
    fill: #711a23;
    fill: #f2bbc1;
}

.profile_img_upload_container {
    display: flex;
}

.profil_img_preview {
    flex: 0 0 68px;
    height: 68px;
    border-radius: 6px;
    border: 2px solid #fff;
    box-shadow: inset 0px 0px 5px rgb(0 0 0 / 9%), rgb(0 0 0 / 7%) 0px 4px 5px 0px;
    margin-right: 20px;
    background-size: cover;
    background-position: center;
}

.profile_img_upload_wrapper {
    border: 2px dashed #eceff1;
    border-radius: 8px;
    width: 100%;
    height: 120px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #8b959e;
    background-color: #f9fbfc;
    font-weight: initial;
    line-height: initial;
}

.profile_img_upload_wrapper span:first-child {
    margin-right: 3px;
}

.profile_img_upload_wrapper svg {
    fill: #374049;
    height: 30px;
    background: rgb(238 240 244);
    width: 30px;
    padding: 5px;
    border-radius: 8px;
}

.profil_img_preview.profil_img_preview_empty {
    background: rgb(238 240 244);
    display: flex;
    align-items: center;
    justify-content: center;
}

.profil_img_preview.profil_img_preview_empty svg {
    fill: #6c7784;
}

.input_side.input_side_padding {
    padding-top: 8px;
    margin-bottom: 8px;
}

.two_side_submit .btn.btn_primary {
    padding: 0 20px;
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
   }
}
.input_skeleton {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: #f0f1f3;
    height: 36px;
    border-radius: 8px;
}
.input_skeleton:after, .api_card_skeleton:after, .task_card_skeleton:after,
.tab_admin_skeleton:after, .api_title_skeleton:after, .api_p_skeleton:after,
.api_tab_skeleton:after, .payment_table_skeleton:after,
.history_item.history_item_skeleton span.history_icon:after,
.history_item.history_item_skeleton .history_wrapper:after,
.history_title_skeleton:after,
.api_name.api_name_skeleton span:after,
.api_description_skeleton span:after,
.api_endpoint_id.api_endpoint_id_skeleton > span:after,
span.api_documentation_h5_skeleton:after,
.api_documentation_param_skeleton:after,
.flex_left_side .tabs .tab.tab_skeleton:after,
.api_code.api_code_skeleton:after,
span.api_plans_price_value.api_plans_price_value_skeleton:after,
.api_plans_btn_skeleton:after,
.api_log_date.api_log_date_skeleton span:after,
.api_log.api_log_skeleton span.api_log_status:after,
.api_log.api_log_skeleton .api_log_endpoint:after,
.api_log.api_log_skeleton span.api_log_time:after,
.api_log.api_log_skeleton span.api_log_length:after,
.api_log_detail.api_log_detail_skeleton span.api_log_status:after,
.api_log_detail.api_log_detail_skeleton span.api_log_detail_value:after,
.api_log_detail.api_log_detail_vert.api_log_detail_skeleton
.api_log_param > .api_log_detail > span.api_log_deatil_name:after,
.api_plans_item.api_plans_item_skeleton:after,
.card_skeleton:after, .api_plans_title_skeleton:after
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
     90deg,
     rgba(255, 255, 255, 0) 0,
     rgba(255, 255, 255, 0.2) 20%,
     rgba(255, 255, 255, 0.5) 50%,
     rgba(255, 255, 255, 0)
    );
    animation: shimmer 1s infinite;
    content: '';
}

.tab_admin_skeleton:after, .api_title_skeleton:after, .api_p_skeleton:after, .api_tab_skeleton:after {
    background-image: linear-gradient(
     90deg,
     rgba(255, 255, 255, 0) 0,
     rgba(255, 255, 255, 0.1) 20%,
     rgba(255, 255, 255, 0.25) 50%,
     rgba(255, 255, 255, 0)
    );
}

.profile_img_upload_container input[type="file"] {
    display: none;
}

.two_side_submit .btn.btn_primary.btn_icon {
    padding: 0;
}

.two_side_submit .btn.btn_indicator {
    margin-right: 12px;
}

.two_side_submit .btn.btn_indicator.btn_error {
    padding: 0 16px 0 12px;
}

.two_side_submit .btn.btn_indicator.btn_error span {
    margin-left: 8px;
    color: #f34343;
}

.password_visible_btn {
    bottom: 4px;
    position: absolute;
    right: 4px;
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s all ease;
}

.password_visible_btn svg {
    position: static;
    height: 18px;
    width: 18px;
    fill: #6c7784;
}

.password_visible_btn:hover {
    background: #eef0f4;
}

.password_visible_btn:active {
    background: #dadce0;
}

.password_visible_btn:active svg {
    fill: #374049;
}

.disable_select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.profile_img_upload_btns {
    display: flex;
    margin-bottom: 8px;
    margin-top: 4px;
}

.profile_img_upload_btn {
    margin-right: 12px;
    display: flex;
}

.profile_img_upload_btn:last-child {
    margin-right: 0;
}

.profile_img_upload_btn svg {
    border: 1px solid #dadce0;
    box-shadow: 0px 1px 5px rgb(0 0 0 / 7%);
    background: #fff;
    height: 32px;
    width: 32px;
    cursor: pointer;
    transition: 0.2s all ease;
}

#undo_profile_img svg {
    fill: #8697a3;
    background: transparent;
    border-color: rgb(134 151 163 / 40%);
    box-shadow: none;
}

#delete_profile_img svg {
    fill: #f34343;
}

.profile_img_upload_btn:hover svg {
    box-shadow: 0px 1px 5px rgb(0 0 0 / 16%);
    border-bottom: 1px solid hsl(220 9% 80% / 1);
}

.profile_img_upload_btn:active svg {
    background: #fafafb;
    border-bottom: 1px solid hsl(220 9% 80% / 1);
    box-shadow: inset 0px 3px 5px -4px rgba(64, 68, 82, 0.20);
}

#undo_profile_img:hover svg {
    background: rgb(242 244 247);
    fill: #798994;
}

#undo_profile_img:active svg {
    background: hsl(214.29deg 9.59% 85.69%);
    fill: #6c7784;
}

.profile_img_upload_wrapper label {
    cursor: pointer;
}

label[for="avatar"] {
    text-decoration: underline;
}

.title_search_avatar_container {
    height: 80px;
    width: 80px;
    border-radius: 10px;
    margin-right: 20px;
    background-position: center;
    background-size: cover;
    border: 2px solid #fff;
    box-shadow: inset 0px 0px 5px rgb(0 0 0 / 9%), rgb(0 0 0 / 7%) 0px 4px 5px 0px;
}

.profile_img_upload_wrapper span.avatar_error {
    color: #f34343;
}

.api_plans_item.api_plans_item_skeleton {
    height: 218px;
    display: block;
    position: relative;
    overflow: hidden;
    background: #f0f1f3;
    border: 0;
}