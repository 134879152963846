.api_balances_container table.table_balances {
    width: 100%;
    background: #fff;
    border: 1px solid #dadce0;
    box-shadow: 0 1px 3px 1px rgba(60,64,67,.03);
}

.api_balances_container td.table_balances_item.table_balances_item_init_width {
    width: initial;
    overflow-wrap: anywhere;
    padding: 10px 15px;
    font-weight: 400;
}

.api_balances_container td.table_balances_item.table_balances_item_4 {
    width: 25%;
    overflow-wrap: anywhere;
    padding: 6.5px 15px;
}

.api_balances_container td.table_balances_item {
    width: 50%;
    color: #1a1f30;
    font-weight: 500;
    line-height: 1.4;
    font-size: 13px;
    text-align: left;
}

.api_balances_container tr.table_balances_row {
    vertical-align: top;
    border-bottom: 1px solid #dadce0;
}


.api_balances_container td.table_balances_item input[type="text"] {
    height: 32px !important;
    box-shadow: none !important;
    border-bottom: 0;
}

.api_balances_container tr.table_balances_apis {
    height: 32px;
    border-bottom: 1px solid #dadce0;
}

.api_balances_container td.table_balances_item.table_balances_item_input {
    height: 32px !important;
    overflow: hidden;
}

.api_balances_container .table_balances_item_input {
    height: 32px;
    position: relative;
    margin-left: 15px;
}

.api_balances_container .input_bar_focus {
    width: 100%;
    position: absolute;
    height: 1px;
    background: #dbdde1;
    bottom: -1px;
}

.api_balances_container tr.table_balances_apis:last-child {
    border-bottom: 0;
}

.api_balances_container input[type="text"]:focus + .input_bar_focus {
    background: #2139da;
}

.api_balances_container th.table_balances_item.table_balances_item_4 {
    background: #f9fbfc;
    color: #1a1f30;
    font-weight: 500;
    line-height: 1.4;
    font-size: 13px;
    text-align: left;
    padding: 6.5px 15px;
}

.api_balances_container th.table_balances_item.table_balances_item_4.table_balances_item_init_width {
    padding: 0 15px;
    height: 40px;
    align-content: center;
    cursor: pointer;
}

.api_balances_container th.table_balances_api_container {
    background: #f9fbfc;
}

.api_balances_container .table_balances_item_input input {
    text-align: left !important;
}

.api_balances_container th.table_balances_api_container tr.table_balances_apis td:last-child span {
    padding-left: 15px;
}


.api_balances_container input.api_balances_search {
    border-radius: 0 !important;
    width: 400px;
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    border-color: rgba(255,255,255,0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    background-color: inherit;
    height: 36px !important;
    border-top: 1px solid rgba(255,255,255,0.25) !important;
    border-left: 1px solid rgba(255,255,255,0.25) !important;
    border-right: 1px solid rgba(255,255,255,0.25) !important;
    box-shadow: none !important;
    padding-left: 40px;
    padding-top: 2px;
    color: #fff !important;
    text-align: left !important;
}

.api_balances_container input.api_balances_search::-webkit-input-placeholder {
    color: rgb(255 255 255 / 60%);
}
  
.api_balances_container input.api_balances_search:-ms-input-placeholder {
    color: rgb(255 255 255 / 60%);
}
  
.api_balances_container input.api_balances_search::placeholder {
    color: rgb(255 255 255 / 60%);
}

.api_balances_container .api_balances_input_search {
    position: absolute;
    top: -116px;
    right: 0;
    z-index: 2;
    display: flex;
}

.api_balances_container .api_balances_input_search svg {
    position: absolute;
    z-index: 3;
    height: 20px;
    top: 9px;
    left: 10px;
    width: 20px;
    fill: rgb(255 255 255 / 60%);
}

.api_balances_container input.api_balances_search:hover {
    border-bottom: 1px solid rgba(255,255,255,0.25) !important;
}

.btn_disable_user {
    color: #f34343;
    text-decoration: underline;
    cursor: pointer;
}

.load_more_user_btn {
    margin-top: 30px;
    align-items: center;
    background: #fff;
    border: 1px solid #dadce0;
    box-shadow: 0 1px 1px rgb(0 0 0/4%);
    cursor: pointer;
    display: flex;
    height: 36px;
    justify-content: center;
    padding: 0 10px;
    text-decoration: none;
    transition: all .2s ease;
    width: 230px;
    color: #2139da;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
    margin: 30px auto 0 auto;
}

.load_more_user_btn.loading {
    color: #8b959e;
    background: #f9fbfc;
    cursor: default;
}

.api_balances_input_search.api_users_checks {
    display: flex;
    height: 36px;
    background-image: linear-gradient(135deg, rgba(255, 255, 255, .05), rgba(255, 255, 255, 0.02));
    border: 1px solid rgba(255, 255, 255, 0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    align-items: center;
    padding: 5px;
}

.api_balances_input_search.api_users_checks > div {
    color: rgb(255 255 255 / 90%);
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 13px;
    margin-right: 5px;
    border: 1px solid transparent;
    letter-spacing: 0.3px;
    cursor: pointer;
}

.api_balances_input_search.api_users_checks > div.active {
    background-image: linear-gradient(135deg, rgba(255, 255, 255, .2), rgba(255, 255, 255, 0.06));
    border-color: rgba(255, 255, 255, 0.25);
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
}

.api_balances_input_search.api_users_checks > div:last-child {
    margin-right: 0;
}

.btn_disable_user.btn_enable_user {
    color: #0aae67;
}

.admin_user_popup_container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000057;
}

.admin_user_popup {
    background: #fff;
    border: 1px solid #dadce0;
    box-shadow: 0 1px 1px rgb(0 0 0/4%);
    padding: 18px;
    width: 100%;
    max-width: 480px;
}

.admin_user_btn_flex {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
}

.admin_user_btn_flex > div {
    background: #fff;
    border: 1px solid #dadce0;
    box-shadow: 0 1px 1px rgb(0 0 0/4%);
    padding: 10px 15px;
    color: #1a1f30;
    font-size: 13px;
    line-height: 1;
    font-weight: 500;
    cursor: pointer;
}

.admin_user_popup p {
    font-size: 16px;
}

.admin_user_popup p span {
    display: block;
    color: #1a1f30;
}

.admin_user_btn_flex .admin_user_popup_enable {
    color: #0aae67;
}


.admin_user_btn_flex .admin_user_popup_disable {
    color: #f34343;
}
.table_balances_item_init_nowrap {
    text-wrap: nowrap;
}

.api_balances_container th.table_balances_item.table_balances_item_4.table_balances_item_init_width svg {
    height: 20px;
    fill: #2139da;
}

.api_balances_container th.table_balances_item.table_balances_item_4.table_balances_item_init_width span {
    display: flex;
    line-height: 1;
    align-items: center;
}

.api_balances_container .api_balances_input_search.api_balances_input_search_left {
    right: initial;
}