.body > .wrapper > h1 {
  margin: 0;
  line-height: 1;
  font-weight: 500;
  font-size: 26px;
  color: #374049;
  margin-bottom: 25px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table.payment_table {
  box-shadow: 0px 1px 1px rgb(0 0 0 / 4%);  
  border-radius: 8px;
}

td {
  padding: 0;
}

thead td .table_element {
  color: #1a1f30;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  display: block;
  background: #f9fbfc;
  padding: 10px;
  border-top: 1px solid #dadce0;
  border-bottom: 1px solid #dadce0;
  height: 34px;
  cursor: pointer;
}

thead td:first-child .table_element {
  border-top-left-radius: 8px;
  border-left: 1px solid #dadce0;
}

thead td:last-child .table_element {
  border-top-right-radius: 8px;
  border-right: 1px solid #dadce0;
}

tbody td .table_element {
  display: flex;
  padding: 10px;
  background: #fff;
  color: #8b959e;
  font-size: 13px;
  line-height: 1;
  font-weight: 400;
  min-height: 58px;
  align-items: center;
}

tbody td:first-child .table_element {
  border-left: 1px solid #dadce0;
}

tbody td:last-child .table_element {
  border-right: 1px solid #dadce0;
}

tbody .table_element {
  border-bottom: 1px solid #dadce0;
}

tbody tr:last-child td:first-child .table_element {
  border-bottom-left-radius: 8px;
}

tbody tr:last-child td:last-child .table_element {
  border-bottom-right-radius: 8px;
}

tbody td:first-child .table_element {
  color: #1a1f30;
  font-weight: 500;
  line-height: 1.4;
}

.tb_plan_date span:last-child {
    font-weight: 400;
    font-size: 10px;
    line-height: 1.5;
    display: block;
    color: #8697a3;
}

.tb_plan_date span:first-child {
    height: 22px;
    color: #374049;
    font-weight: 400;
    font-size: 13px;
    align-items: center;
    display: flex;
    margin-right: auto;
}

.table_element.tb_plan_date {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.tb_plan_count span:last-child {
    font-weight: 400;
    font-size: 11px;
    line-height: 1;
    display: block;
    color: #8697a3;
    letter-spacing: 0.4px;
}


.tb_plan_count span:first-child {
    color: #374049;
    font-weight: 500;
    font-size: 15px;
    align-items: center;
    display: flex;
    line-height: 1;
    margin-right: 2px;
}


.tb_plan_count_wrapper {
    display: flex;
    align-items: flex-end;
}

tbody .table_element.tb_plan_price {
    color: #8697a3;
    font-weight: 400;
    font-size: 15px;
    align-items: center;
    display: flex;
    line-height: 1;
}

.table_element.tb_plan_type span {
    background: rgb(33 57 218 / 10%);
    border-radius: 100px;
    padding: 0 8px;
    height: 22px;
    display: inline-flex;
    margin-bottom: 4px;
    color: #2139da;
    font-weight: 500;
    font-size: 12px;
    align-items: center;
    text-transform: uppercase;
}

.table_element.tb_plan_type span:before {
    content: "";
    height: 7px;
    width: 7px;
    background: #2139da;
    border-radius: 10px;
    margin-right: 7px;
}

.table_element.tb_plan_price span {
    margin-right: 6px;
    font-weight: 500;
    color: #374049;
}

.btn_cancel {
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    padding: 0 13px;
    height: 28px;
    font-size: 12px;
    font-weight: 500;
    color: #f44336;
    cursor: pointer;
    border: 1px solid rgb(244 64 52 / 25%);
    background: rgb(244 67 54 / 3%);
}

.btn_activate {
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    padding: 0 13px;
    height: 28px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #9bdec1;
    color: #0aae67;
    background: rgb(10 174 103 / 9%);
}

.payment_table td:last-child {
    width: 110px;
}

td:first-child .table_element {
    padding-left: 18px;
}

.switch {
    height: 22px;
    width: 44px;
    background: #f9fbfc;
    border-radius: 17px;
    border: 1px solid #dadce0;
    padding: 2px;
    display: flex;
    cursor: pointer;
    transition: 0.3s all ease;
}

.switch_ball {
    height: 16px;
    width: 16px;
    background: #fff;
    border-radius: 14px;
    border: 1px solid #dadce0;
    transition: 0.3s all ease;
    position: relative;
    left: 0;
}

.switch.active {
    background: hsl(232deg 74% 49% / 20%);
    border-color: rgb(33 57 218 / 50%);
}

.switch.active .switch_ball {
    border-color: #2139da;
    background: #2139da;
    left: 22px;
}

.title_search.title_search_info_center {
    text-align: center;
    justify-content: center;
    padding-bottom: 0;
}

.title_search_info .tabs {
    margin: 30px auto 0 auto;
    display: flex;
    align-items: flex-end;
    padding: 0 5px;
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
    justify-content: center;
    height: 44px;
    position: relative;
    width: 274px;
}

.title_search_info  .tabs .tab {
    color: rgb(255 255 255 / 90%);
    font-size: 13px;
    line-height: 1;
    font-weight: 500;
    padding: 8px 14px 16px 14px;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 132px;
    margin-right: 10px;
}

.title_search_info .tabs .tab:last-child {
    margin-right: 0;
}

.title_search_info .tabs .tab.active {
    color: rgb(255 255 255 / 100%);
}

.tab_controll {
    width: 72px;
    height: 5px;
    position: absolute;
    bottom: 0px;
    border-radius: 6px 6px 0 0;
    background-color: rgba(255,255,255,.4);
    background-image: linear-gradient(135deg,rgba(255,255,255,.2),rgba(255,255,255,0.06));
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
    border: 1px solid rgba(255,255,255,0.25);
    border-bottom: 0;
    transition: 0.3s ease all;
}

#payment_page .tab_controll {
  left: 30px;
}

#payment_page .tab_controll.active {
    left: 172px;
}

.table_element.tb_plan_action {
  width: 120px;
}
.history_item {
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
  position: relative;
}

.history_item p {
  width: 100%;
  color: #6c7784;
  font-size: 13px;
}

.history_item p span {
  color: #414552;
  font-weight: 500;
  cursor: pointer;
}

.history_price {
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: auto;
  background: #0aae67;
  padding: 4px 6px 3px 6px;
  border-radius: 5px;
  line-height: 1;
  margin-right: auto;
  margin-top: 9px;
}

span.history_icon {
  height: 20px;
  flex: 0 0 20px;
  border-radius: 20px;
  margin-right: 10px;
  position: relative;
  top: 5px;
  z-index: 1;
  box-shadow: 0 1px 3px 1px rgba(60,64,67,.03);
  background: #2139da;
}

.history_content {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 3px 1px rgba(60,64,67,.03);
  display: flex;
  border: 1px solid #dadce0;
  padding: 6px 10px;
  flex-direction: column;
}

.history_time {
  font-size: 11px;
  color: #8b959e;
  margin-top: 5px;
}

.history_wrapper {
  display: flex;
  flex-direction: column;
}

.history_item:before {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 100%;
  background: #dadce0;
  top: 15px;
  left: 9.5px;
}

.history_item + h4.history_title {
  margin-top: 40px;
}

.history_item p span:hover {
  text-decoration: underline;
}

.history_item.history_item_last:before {
  display: none;
}

.white_side.white_side_large {flex: 0 0 70%;}

.history_list {
  max-width: 1216px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 35px;
}


.btn.btn_small {
  height: 30px;
}

.btn.btn_small span {
  font-size: 12px;
}

.btn.btn_small.btn_error span {
  color: #f34343;
  font-weight: 400;
}

/* .btn.btn_small.btn_error {
  border-color: #fab4b4;
} */

.btn.btn_small.btn_success span {
  color: #0aae67;
  font-weight: 400;
}

/* .btn.btn_small.btn_success {
    border-color: #9ddfc2;
} */

.tb_plan_date span.tb_plan_date_late:first-child {
  color: #f34343;
}

.payment_table_skeleton {
  height: 266px;
  width: 100%;
  border-radius: 8px;
  background-color: #f0f1f3;
  display: block;
  position: relative;
  overflow: hidden;
}

.history_title_skeleton {
  height: 18px;
  width: 110px;
  border-radius: 4px;
  background-color: #f0f1f3;
  display: block;
  position: relative;
  overflow: hidden;
  margin-bottom: 12px;
}

.history_item + .history_title_skeleton {
  margin-top: 40px;
}

.history_item.history_item_skeleton .history_wrapper {
  height: 32px;
  background: #f0f1f3;
  width: 480px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}

.history_item.history_item_skeleton span.history_icon {
  background: #f0f1f3;
  position: relative;
  overflow: hidden;
}

.history_item.history_item_skeleton:before {
  background: #f0f1f3;
}

.plan_empty_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plan_empty_container h3, .history_empty_container h3  {
  margin: 0;
  line-height: 1;
  font-weight: 500;
  font-size: 20px;
  color: #374049;
  margin-bottom: 8px;
}

.plan_empty_container p, .history_empty_container p {
  margin: 0;
  font-size: 13px;
  color: #8b959e;
  margin-bottom: 4px;
}

.plan_empty_container .btn.btn_primary, .history_empty_container .btn.btn_primary  {
  margin-top: 10px;
  padding: 0 20px;
}

.plan_empty_icon svg:first-child {
  height: 77px;
  width: 77px;
  fill: #d1d3d6;
}

.plan_empty_icon {
  position: relative;
  margin-bottom: 8px;
}

.plan_empty_btn_flex {
  display: flex;
  margin-top: 10px;
}

.plan_empty_btn_flex a.btn.btn_primary {
  margin-left: 10px;
  margin-top: 0;
}

.history_empty_container {
  display: flex;
  flex-direction: column;
}